<template>
  <div class="container">
    <Header :index="6"></Header>
    <div class="content">
      <div class="col-left">
        <div class="row-header" ref="top">默认&nbsp;|&nbsp;排行</div>
        <div
          class="list"
          v-for="(item, index) in collectData"
          :key="index"
          @click="todetail(item.id)"
          :style="
            index === collectData.length - 1 || index === page * pageSize - 1
              ? ''
              : 'border-bottom: 1px solid #eeeeee;'
          "
        >
          <div class="comment">
            <img
              style="width: 100%; height: 100%"
              src="@/assets/home/comment.png"
              alt=""
            />
            <p>{{ item.commentsNum }}</p>
          </div>
          <div class="detail">
            <p>{{ item.title }}</p>
            <p>{{ item.content }}</p>
            <div>
              <div class="img" v-for="(i, j) in item.photosArr" :key="j">
                <img :src="i" alt="" />
              </div>
            </div>
          </div>
          <div class="right">
            <p>
              <i class="el-icon-user-solid" style="margin-right: 10px"></i
              >{{ item.userNickName }}
            </p>
            <p>{{ item.publishTime }}</p>
          </div>
        </div>
        <div style="width: 100%; text-align: center; margin: 70px 0">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="pageSize"
            layout="prev, pager, next, jumper, total, sizes"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div class="col-right">
        <img src="@/assets/home/edit.png" alt="" @click="add" />
        <img @click="fresh" src="@/assets/home/fresh.png" alt="" />
        <img @click="intoView" src="@/assets/home/top.png" alt="" />
      </div>
    </div>
    <Concat></Concat>
    <Footer></Footer>
    <van-popup v-model="show" get-container="body" z-index="99">
      <div class="popup-content">
        <div class="popup-head">发帖</div>
        <el-form label-width="80px" style="margin: 30px 66px 0">
          <el-form-item label="标题：">
            <el-input
              placeholder="请输入标题"
              v-model="formData.title"
            ></el-input>
          </el-form-item>
          <el-form-item label="内容：">
            <div class="inputBox">
              <textarea
                type="text"
                placeholder="请输入"
                rows="6"
                v-model="formData.content"
              />
              <div class="inputImg">
                <div class="img" v-for="(item, index) in photos" :key="index">
                  <img :src="item" alt="" />
                </div>
                <el-upload
                  :action="baseUrl + '/web/upload/uploadimg'"
                  :headers="uploadHead"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                >
                  <div class="upload">
                    <van-icon name="photograph" size="50px" />
                    <p>上传照片</p>
                  </div>
                </el-upload>
                <div style="width: 150px"></div>
                <div style="width: 150px"></div>
              </div>
            </div>
          </el-form-item>
        </el-form>
        <div class="popup-btns">
          <div class="popup-cancel" @click="popup_cancel">取消</div>
          <div class="popup-confirm" @click="popup_confirm">发布</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<!--index.vue创建时间：2021/11/8 17:02:31-->
<script>
import Header from "@/components/header";
import Concat from "@/components/concat";
import Footer from "@/components/footer";
import { baseUrl } from "@/api/config";
export default {
  components: {
    Header,
    Concat,
    Footer,
  },
  data() {
    return {
      baseUrl,
      //图片上传Headers
      uploadHead: {
        Authorization: sessionStorage.getItem("token"),
      },
      show: false,
      page: 1,
      pageSize: 10,
      total: null,
      collectData: [],
      formData: {},
      photos: [],
    };
  },
  mounted() {
    this.network();
  },
  methods: {
    network() {
      this.http("/web/posts/pageinhall", {
        pageNum: this.page,
        pageSize: this.pageSize,
        sortType: 1,
      }).then((res) => {
        if (res.code === 200) {
          this.collectData = res.data.rows;
          this.total = res.data.total;
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.network();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.network();
    },
    handleAvatarSuccess(res) {
      this.photos.push(res.data);
      this.formData.photos = this.photos.join(",");
    },
    todetail(id) {
      this.$router.push({path:"/detailcollection",query:{id}});
    },
    async add(){
      let flag = await this.http("/web/user/checkuserinfocomplete").then(
          (res) => {
            if (!res.data) {
              this.$store.commit("open", true);
              return false;
            }
            if (res.data) {
              return true;
            }
          }
      );
      if (!flag) {
        return;
      }
      this.show=true
    },
    popup_cancel() {
      this.show = false;
    },
    popup_confirm() {
      if (!this.formData.title) {
        this.$message("请输入标题");
        return;
      }
      if (!this.formData.content) {
        this.$message("请输入内容");
        return;
      }
      this.http("/web/posts/add", this.formData, "post").then((res) => {
        if (res.code === 200) {
          this.$message.success("发布成功");
          this.photos = [];
          this.formData = {};
          this.show = false;
        }
      });
    },
    fresh(){
      window.location.reload()
    },
    intoView(){
      this.$refs.top.scrollIntoView({
        behavior:"smooth"
      })
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  background: #f6f6f6;
  > .content {
    width: 1110px;
    display: flex;
    margin: 50px auto;
    justify-content: space-between;
    > .col-left {
      width: 1050px;
      background-color: #fff;
      > .row-header {
        padding-left: 16px;
        height: 70px;
        line-height: 70px;
        background: #0f86d8;
        font-size: 24px;
        font-weight: 400;
        color: #feffff;
        margin-bottom: 20px;
      }
      > .list {
        width: 950px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding: 33px 0 24px;
        cursor: pointer;
        > .comment {
          width: 52px;
          height: 30px;
          line-height: 30px;
          position: relative;
          > p {
            width: 100%;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        > .detail {
          width: 740px;
          display: flex;
          flex-direction: column;
          > p:first-child {
            font-size: 14px;
            font-weight: 400;
            color: #0f86d8;
          }
          > p:nth-of-type(2) {
            font-size: 12px;
            font-weight: 500;
            color: #333333;
            line-height: 24px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            word-break: break-all;
            overflow: hidden;
            margin-top: 24px;
          }
          > div {
            display: flex;
            flex-wrap: wrap;
            > .img {
              width: 175px;
              height: 130px;
              margin: 20px 10px 20px 0;
              overflow: hidden;
              box-sizing: border-box;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
        > .right {
          display: flex;
          flex-direction: column;
          align-items: center;
          > p:first-child {
            font-size: 12px;
            font-weight: 400;
            color: #999999;
          }
          > p:last-child {
            font-size: 12px;
            font-weight: 400;
            color: #9a9a9a;
            margin-top: 30px;
          }
        }
      }
    }
    > .col-right {
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      > img:nth-of-type(1) {
        position: fixed;
        top: 40%;
        transform: translateY(-50%);
      }
      > img:nth-of-type(2) {
        position: fixed;
        top: 46%;
        transform: translateY(-50%);
      }
      > img:nth-of-type(3) {
        position: fixed;
        top: 52%;
        transform: translateY(-50%);
      }
      > img {
        width: 45px;
        height: 45px;
        cursor: pointer;
      }
    }
  }
}
.popup-content {
  width: 900px;
  padding-bottom: 68px;
  > .popup-head {
    height: 59px;
    line-height: 59px;
    background: #1b294e;
    padding-left: 27px;
    font-size: 24px;
    font-weight: 400;
    color: #ffffff;
  }
  .inputBox {
    width: calc(100% - 54px);
    border: 1px solid #eeeeee;
    border-radius: 2px;
    padding: 0 27px 20px;
    > textarea {
      width: 100%;
      border: none;
      resize: none;
    }
    .inputImg {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      > .img {
        width: 150px;
        height: 150px;
        overflow: hidden;
        margin-bottom: 15px;
        border-radius: 5px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .upload {
        width: 150px;
        height: 150px;
        display: flex;
        flex-direction: column;
        color: #999999;
        align-items: center;
        justify-content: center;
        background-color: #eeeeee;
        cursor: pointer;
      }
    }
  }
  > .popup-btns {
    display: flex;
    justify-content: right;
    > .popup-cancel {
      width: 200px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background: #f6f6f6;
      border-radius: 5px;
      font-size: 24px;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
    > .popup-confirm {
      width: 200px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background: #00b76a;
      border-radius: 5px;
      font-size: 24px;
      font-weight: 400;
      color: #ffffff;
      margin: 0 50px;
      cursor: pointer;
    }
  }
}
</style>
