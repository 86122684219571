<template>
  <div class="container">
    <Header :index="6"></Header>
    <div class="content">
      <div class="col-left">
        <div class="row-head" ref="top">{{ detailData.title }}</div>
        <div class="row-content">
          <div class="row-detail">
            <div class="col-left">
              <div class="master"><p>楼主</p></div>
              <div class="head_portrait">
                <img :src="detailData.userAvatar" alt="" />
              </div>
              <p>{{ detailData.userNickName }}</p>
              <p>{{ detailData.publishTime }}</p>
            </div>
            <div class="col-right">
              <p>
                {{ detailData.content }}
              </p>
              <div class="imgs">
                <img
                  v-for="(item,index) in detailData.photosArr"
                  :key="index"
                  :src="item"
                  alt=""
                />
              </div>
              <div class="textarea">
                <textarea rows="3" placeholder="写下留言" v-model="text"></textarea>
                <p @click="saveText">留言</p>
              </div>
            </div>
          </div>
          <div class="list" v-for="(item, index) in replyData" :key="index">
            <div class="col-left">
              <div class="head_portrait">
                <img :src="item.selfUserAvatar" alt="" />
              </div>
              <p>{{ item.selfUserName }}</p>
              <p>{{ item.publishTime }}</p>
            </div>
            <div class="col-right">
              {{ item.content }}
            </div>
          </div>
        </div>
        <div
          style="
            width: 100%;
            text-align: center;
            padding: 30px 0;
            background: #f5f7fa;
          "
        >
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="pageSize"
            layout="prev, pager, next, jumper, total, sizes"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div class="col-right">
        <img src="@/assets/home/edit.png" alt="" @click="add" />
        <img @click="fresh" src="@/assets/home/fresh.png" alt="" />
        <img @click="intoView" src="@/assets/home/top.png" alt="" />
      </div>
    </div>
    <Concat></Concat>
    <Footer></Footer>
    <van-popup v-model="show" get-container="body" z-index="99">
      <div class="popup-content">
        <div class="popup-head">发帖</div>
        <el-form label-width="80px" style="margin: 30px 66px 0">
          <el-form-item label="标题：">
            <el-input
              placeholder="请输入标题"
              v-model="formData.title"
            ></el-input>
          </el-form-item>
          <el-form-item label="内容：">
            <div class="inputBox">
              <textarea
                type="text"
                placeholder="请输入"
                rows="6"
                v-model="formData.content"
              />
              <div class="inputImg">
                <div class="img" v-for="(item, index) in photos" :key="index">
                  <img :src="item" alt="" />
                </div>
                <el-upload
                  :action="baseUrl + '/web/upload/uploadimg'"
                  :headers="uploadHead"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                >
                  <div class="upload">
                    <van-icon name="photograph" size="50px" />
                    <p>上传现场照片</p>
                  </div>
                </el-upload>
                <div style="width: 150px"></div>
                <div style="width: 150px"></div>
              </div>
            </div>
          </el-form-item>
        </el-form>
        <div class="popup-btns">
          <div class="popup-cancel" @click="popup_cancel">取消</div>
          <div class="popup-confirm" @click="popup_confirm">发布</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<!--index.vue创建时间：2021/11/9 10:49:29-->
<script>
import Header from "@/components/header";
import Concat from "@/components/concat";
import Footer from "@/components/footer";
import { baseUrl } from "@/api/config";
export default {
  components: {
    Header,
    Concat,
    Footer,
  },
  data() {
    return {
      baseUrl,
      //图片上传Headers
      uploadHead: {
        Authorization: sessionStorage.getItem("token"),
      },
      page: 1,
      pageSize: 10,
      total:null,
      show: false,
      detailData: {},
      text:"",
      replyData: [],
      formData: {},
      photos: [],
    };
  },
  mounted() {
    if(this.$route.query.id===undefined){
      this.$router.push("/collection");
      return;
    }
    this.network();
    this.comment()
  },
  methods: {
    network() {
      this.http(`/web/posts/getbyid/${this.$route.query.id}`).then((res) => {
        if (res.code === 200) {
          this.detailData = res.data;
        }
      });
    },
    comment(){
      this.http(`/web/postscomment/page/${this.$route.query.id}`,{
        pageNum:this.page,
        pageSize:this.pageSize,
      }).then((res) => {
        if(res.code === 200) {
          this.replyData=res.data.rows;
          this.total=res.data.total
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.comment()
    },
    handleCurrentChange(val) {
      this.page=val;
      this.comment()
    },
    handleAvatarSuccess(res) {
      this.photos.push(res.data);
      this.formData.photos = this.photos.join(",");
    },
    async add(){
      let flag = await this.http("/web/user/checkuserinfocomplete").then(
          (res) => {
            if (!res.data) {
              this.$store.commit("open", true);
              return false;
            }
            if (res.data) {
              return true;
            }
          }
      );
      if (!flag) {
        return;
      }
      this.show=true
    },
    popup_cancel() {
      this.show = false;
    },
    popup_confirm() {
      if (!this.formData.title) {
        this.$message("请输入标题");
        return;
      }
      if (!this.formData.content) {
        this.$message("请输入内容");
        return;
      }
      this.http("/web/posts/add", this.formData, "post").then((res) => {
        if (res.code === 200) {
          this.$message.success("发布成功");
          this.photos = [];
          this.formData = {};
          this.show = false;
        }
      });
    },
    saveText(){
      if(!sessionStorage.getItem("token")){
        this.$message("你还没有登录，请先登录！");
        return
      }
      if(this.text){
        this.http('/web/postscomment/add',{
          content:this.text,
          postsId:this.$route.query.id
        },"post").then(res=>{
          if(res.code===200){
            this.$message.success("留言成功");
            this.text="";
            this.comment()
          }
        })
      }
    },
    fresh(){
      window.location.reload()
    },
    intoView(){
      this.$refs.top.scrollIntoView({
        behavior:"smooth"
      })
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  background: #f6f6f6;
  > .content {
    width: 1110px;
    display: flex;
    margin: 50px auto;
    justify-content: space-between;

    > .col-left {
      width: 1050px;
      background-color: #fff;
      > .row-head {
        padding-left: 40px;
        height: 82px;
        line-height: 82px;
        border-bottom: solid 4px #0f86d8;
      }
      > .row-content {
        padding: 0 60px;
        > .row-detail {
          display: flex;
          justify-content: space-between;
          user-select: none;
          > .col-left {
            position: relative;
            padding-top: 22px;
            display: flex;
            flex-direction: column;
            align-items: center;
            > .master {
              width: 0;
              height: 0;
              border-top: 40px solid #0f86d8;
              border-left: 40px solid transparent;
              position: absolute;
              top: 0;
              right: -35px;
              > p {
                position: absolute;
                top: -36px;
                white-space: nowrap;
                left: -25px;
                font-size: 12px;
                font-weight: 400;
                transform: rotate(45deg);
                color: #ffffff;
              }
            }
            > .head_portrait {
              padding: 5px;
              border: solid 1px #e5e5e5;
              height: fit-content;
              > img {
                width: 78px;
                height: 78px;
              }
            }
            > p:nth-of-type(1) {
              font-size: 12px;
              font-weight: 400;
              color: #0f86d8;
              margin-top: 15px;
            }
            > p:last-child {
              font-size: 12px;
              font-weight: 400;
              color: #9a9a9a;
              margin-top: 15px;
            }
          }
          > .col-right {
            width: 776px;
            display: flex;
            flex-direction: column;
            padding-top: 38px;
            > p {
              font-size: 12px;
              font-weight: 500;
              color: #333333;
              line-height: 24px;
              margin-bottom: 16px;
            }
            > .imgs {
              display: flex;
              flex-wrap: wrap;
              > img {
                max-width: 776px;
                margin-right: 15px;
                margin-bottom: 15px;
              }
            }
            > .textarea {
              width: 756px;
              height: 90px;
              background: #ffffff;
              border: 1px solid #00b76a;
              padding: 10px;
              position: relative;
              margin-bottom: 54px;
              > textarea {
                width: calc(100% - 30px);
                border: none;
                resize: none;
              }
              > p {
                width: 50px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                background: #00b76a;
                border-radius: 2px;
                font-size: 12px;
                font-weight: 400;
                color: #ffffff;
                cursor: pointer;
                margin-top: 10px;
                position: absolute;
                right: 10px;
                &:active {
                  background: rgba(0, 183, 106, 0.7);
                }
              }
            }
          }
        }
        > .list {
          display: flex;
          justify-content: space-between;
          > .col-left {
            padding: 35px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            > .head_portrait {
              padding: 5px;
              border: 1px solid #e5e5e5;
              > img {
                width: 78px;
                height: 78px;
              }
            }
            > p:nth-of-type(1) {
              font-size: 12px;
              font-weight: 400;
              color: #0f86d8;
              margin-top: 15px;
            }
            > p:last-child {
              font-size: 12px;
              font-weight: 400;
              color: #9a9a9a;
              margin-top: 15px;
            }
          }
          > .col-right {
            width: 778px;
            padding: 54px 0;
            border-top: 1px solid #eeeeee;
            font-size: 12px;
            font-weight: 500;
            color: #333333;
            line-height: 24px;
          }
        }
      }
    }
    > .col-right {
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      > img:nth-of-type(1) {
        position: fixed;
        top: 40%;
        transform: translateY(-50%);
      }
      > img:nth-of-type(2) {
        position: fixed;
        top: 46%;
        transform: translateY(-50%);
      }
      > img:nth-of-type(3) {
        position: fixed;
        top: 52%;
        transform: translateY(-50%);
      }
      > img {
        width: 45px;
        height: 45px;
        cursor: pointer;
      }
    }
  }
}
.popup-content {
  width: 900px;
  padding-bottom: 68px;
  > .popup-head {
    height: 59px;
    line-height: 59px;
    background: #1b294e;
    padding-left: 27px;
    font-size: 24px;
    font-weight: 400;
    color: #ffffff;
  }
  .inputBox {
    width: calc(100% - 54px);
    border: 1px solid #eeeeee;
    border-radius: 2px;
    padding: 0 27px 20px;
    > textarea {
      width: 100%;
      border: none;
      resize: none;
    }
    .inputImg {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      > .img {
        width: 150px;
        height: 150px;
        overflow: hidden;
        margin-bottom: 15px;
        border-radius: 5px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .upload {
        width: 150px;
        height: 150px;
        display: flex;
        flex-direction: column;
        color: #999999;
        align-items: center;
        justify-content: center;
        background-color: #eeeeee;
        cursor: pointer;
      }
    }
  }
  > .popup-btns {
    display: flex;
    justify-content: right;
    > .popup-cancel {
      width: 200px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background: #f6f6f6;
      border-radius: 5px;
      font-size: 24px;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
    > .popup-confirm {
      width: 200px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background: #00b76a;
      border-radius: 5px;
      font-size: 24px;
      font-weight: 400;
      color: #ffffff;
      margin: 0 50px;
      cursor: pointer;
    }
  }
}
</style>
