<template>
  <div class="container">
    <!--    发布出售-->
    <van-popup
      v-model="saleShow"
      get-container="body"
      :lazy-render="false"
      :close-on-click-overlay="false"
      z-index="99"
    >
      <div class="popup-content">
        <div class="popup-header">发布出售</div>
        <div class="list">
          <span style="margin-right: 20px">钢厂</span>
          <el-select
            v-model="releaseSale.millName"
            placeholder="请选择"
            style="width: 40%; margin-right: 20px"
            popper-class="screen"
            size="small"
          >
            <el-option
              v-for="(item, index) in gc"
              :key="index"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
          <el-input
            v-model="releaseSale.millName"
            size="small"
            placeholder="或手动输入"
            style="width: 40%"
          ></el-input>
        </div>
        <div class="list">
          <span style="margin-right: 20px">品名</span>
          <el-select
            v-model="releaseSale.productName"
            placeholder="请选择"
            style="width: 40%; margin-right: 20px"
            popper-class="screen"
            size="small"
          >
            <el-option
              v-for="(item, index) in pm"
              :key="index"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
          <el-input
            v-model="releaseSale.productName"
            size="small"
            placeholder="或手动输入"
            style="width: 40%"
          ></el-input>
        </div>
        <div class="list">
          <span style="margin-right: 20px">材质</span>
          <el-select
            v-model="releaseSale.materialQuality"
            placeholder="请选择"
            style="width: 40%; margin-right: 20px"
            popper-class="screen"
            size="small"
          >
            <el-option
              v-for="(item, index) in cz"
              :key="index"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
          <el-input
            v-model="releaseSale.materialQuality"
            size="small"
            placeholder="或手动输入"
            style="width: 40%"
          ></el-input>
        </div>
        <div class="list">
          <span style="margin-right: 20px">规格</span>
          <el-select
            v-model="releaseSale.specs"
            placeholder="请选择"
            style="width: 40%; margin-right: 20px"
            popper-class="screen"
            size="small"
          >
            <el-option
              v-for="(item, index) in gg"
              :key="index"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
          <el-input
            v-model="releaseSale.specs"
            size="small"
            placeholder="或手动输入"
            style="width: 40%"
          ></el-input>
        </div>
        <div class="list">
          <span style="margin-right: 20px">仓库</span>
          <el-select
            v-model="releaseSale.warehouseName"
            placeholder="请选择"
            style="width: 40%; margin-right: 20px"
            popper-class="screen"
            size="small"
          >
            <el-option
              v-for="(item, index) in ck"
              :key="index"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
          <el-input
            v-model="releaseSale.warehouseName"
            size="small"
            placeholder="或手动输入"
            style="width: 40%"
          ></el-input>
        </div>
        <div class="list">
          <span style="margin-right: 20px">数量</span>
          <el-input
            v-model="releaseSale.num"
            size="small"
            placeholder="吨"
            style="width: 36%; margin-right: 20px"
          ></el-input>
          <span style="margin-right: 20px">价格</span>
          <el-input-number
              :controls="false"
              :precision="2"
            v-model="releaseSale.transactionPrice"
            size="small"
            placeholder="元/吨"
            style="width: 38%"
          ></el-input-number>
        </div>
        <div class="list">
          <span style="margin-right: 20px">有效期</span>
          <el-date-picker
            type="date"
            v-model="releaseSale.validityTime"
            size="small"
            placeholder="不选默认今天"
            style="width: 36%; margin-right: 20px"
            format="yyyy.MM.dd"
            value-format="yyyy.MM.dd"
          >
          </el-date-picker>
          <div style="width: 46%"></div>
        </div>
        <div class="list">
          <span style="margin-right: 20px">姓名</span>
          <el-input
            v-model="releaseSale.username"
            size="small"
            disabled
            placeholder="请输入姓名"
            style="width: 36%; margin-right: 20px"
          ></el-input>
          <span style="margin-right: 20px">公司</span>
          <el-input
            v-model="releaseSale.companyName"
            size="small"
            disabled
            placeholder="请输入公司名称"
            style="width: 38%"
          ></el-input>
        </div>
        <div class="list">
          <span style="margin-right: 20px">电话</span>
          <el-input
            v-model="releaseSale.phoneNumber"
            size="small"
            disabled
            placeholder="请输入电话号码"
            style="width: 36%; margin-right: 20px"
          ></el-input>
          <div style="width: 44%"></div>
        </div>
        <div class="popup-btns">
          <div></div>
          <div class="btn">
            <div class="popup-cancel" @click="popup_cancel">取消</div>
            <div class="popup-confirm" @click="confirmSale">发布</div>
          </div>
        </div>
      </div>
    </van-popup>

    <!--    发布求购-->
    <van-popup
      v-model="buyShow"
      get-container="body"
      :lazy-render="false"
      :close-on-click-overlay="false"
      z-index="99"
    >
      <div class="popup-content">
        <div class="popup-header">发布求购</div>
        <div class="list">
          <span style="margin-right: 20px">钢厂</span>
          <el-select
            v-model="releaseBuy.millName"
            placeholder="请选择"
            style="width: 40%; margin-right: 20px"
            popper-class="screen"
            size="small"
          >
            <el-option
              v-for="(item, index) in gc"
              :key="index"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
          <el-input
            v-model="releaseBuy.millName"
            size="small"
            placeholder="或手动输入"
            style="width: 40%"
          ></el-input>
        </div>
        <div class="list">
          <span style="margin-right: 20px">品名</span>
          <el-select
            v-model="releaseBuy.productName"
            placeholder="请选择"
            style="width: 40%; margin-right: 20px"
            popper-class="screen"
            size="small"
          >
            <el-option
              v-for="(item, index) in pm"
              :key="index"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
          <el-input
            v-model="releaseBuy.productName"
            size="small"
            placeholder="或手动输入"
            style="width: 40%"
          ></el-input>
        </div>
        <div class="list">
          <span style="margin-right: 20px">材质</span>
          <el-select
            v-model="releaseBuy.materialQuality"
            placeholder="请选择"
            style="width: 40%; margin-right: 20px"
            popper-class="screen"
            size="small"
          >
            <el-option
              v-for="(item, index) in cz"
              :key="index"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
          <el-input
            v-model="releaseBuy.materialQuality"
            size="small"
            placeholder="或手动输入"
            style="width: 40%"
          ></el-input>
        </div>
        <div class="list">
          <span style="margin-right: 20px">规格</span>
          <el-select
            v-model="releaseBuy.specs"
            placeholder="请选择"
            style="width: 40%; margin-right: 20px"
            popper-class="screen"
            size="small"
          >
            <el-option
              v-for="(item, index) in gg"
              :key="index"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
          <el-input
            v-model="releaseBuy.specs"
            size="small"
            placeholder="或手动输入"
            style="width: 40%"
          ></el-input>
        </div>
        <div class="list">
          <span style="margin-right: 20px">仓库</span>
          <el-select
            v-model="releaseBuy.warehouseName"
            placeholder="请选择"
            style="width: 40%; margin-right: 20px"
            popper-class="screen"
            size="small"
          >
            <el-option
              v-for="(item, index) in ck"
              :key="index"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
          <el-input
            v-model="releaseBuy.warehouseName"
            size="small"
            placeholder="或手动输入"
            style="width: 40%"
          ></el-input>
        </div>
        <div class="list">
          <span style="margin-right: 20px">数量</span>
          <el-input
            v-model="releaseBuy.num"
            size="small"
            placeholder="吨"
            style="width: 36%; margin-right: 20px"
          ></el-input>
          <span style="margin-right: 20px">价格</span>
          <el-input-number
              :controls="false"
              :precision="2"
            v-model="releaseBuy.transactionPrice"
            size="small"
            placeholder="元/吨"
            style="width: 38%"
          ></el-input-number>
        </div>
        <div class="list">
          <span style="margin-right: 20px">有效期</span>
          <el-date-picker
            type="date"
            v-model="releaseBuy.validityTime"
            size="small"
            placeholder="不选默认今天"
            style="width: 36%; margin-right: 20px"
            format="yyyy.MM.dd"
            value-format="yyyy.MM.dd"
          >
          </el-date-picker>
          <div style="width: 46%"></div>
        </div>
        <div class="list">
          <span style="margin-right: 20px">姓名</span>
          <el-input
            v-model="releaseBuy.username"
            disabled
            size="small"
            placeholder="请输入姓名"
            style="width: 36%; margin-right: 20px"
          ></el-input>
          <span style="margin-right: 20px">公司</span>
          <el-input
            v-model="releaseBuy.companyName"
            disabled
            size="small"
            placeholder="请输入公司名称"
            style="width: 38%"
          ></el-input>
        </div>
        <div class="list">
          <span style="margin-right: 20px">电话</span>
          <el-input
            v-model="releaseBuy.phoneNumber"
            disabled
            size="small"
            placeholder="请输入电话号码"
            style="width: 36%; margin-right: 20px"
          ></el-input>
          <div style="width: 44%"></div>
        </div>
        <div class="popup-btns">
          <div></div>
          <div class="btn">
            <div class="popup-cancel" @click="popup_cancel">取消</div>
            <div class="popup-confirm" @click="popup_confirm">发布</div>
          </div>
        </div>
      </div>
    </van-popup>
    <!--    查看详情-->
    <van-popup v-model="detailShow" z-index="99">
      <div class="detail-con">
        <div class="head">详细信息</div>
        <el-form label-width="80px" style="padding-left: 50px">
          <el-form-item label="钢厂" style="margin-bottom: 10px">{{
              detail.millName
            }}</el-form-item>
          <el-form-item label="品名" style="margin-bottom: 10px">{{
              detail.productName
            }}</el-form-item>
          <el-form-item label="规格" style="margin-bottom: 10px">{{
              detail.specs
            }}</el-form-item>
          <el-form-item label="材质" style="margin-bottom: 10px">{{
              detail.materialQuality
            }}</el-form-item>
          <el-form-item label="仓库" style="margin-bottom: 10px">{{
              detail.warehouseName
            }}</el-form-item>
          <el-form-item label="数量" style="margin-bottom: 10px">{{
              detail.num
            }}</el-form-item>
          <el-form-item label="价格" style="margin-bottom: 10px">{{
              detail.transactionPrice
            }}</el-form-item>
          <el-form-item label="有效期" style="margin-bottom: 10px">{{
              detail.validityTime
            }}</el-form-item>
          <el-form-item label="姓名" style="margin-bottom: 10px">{{
              detail.username
            }}</el-form-item>
          <el-form-item label="公司" style="margin-bottom: 10px">{{
              detail.companyName
            }}</el-form-item>
          <el-form-item label="电话" style="margin-bottom: 10px">{{
              detail.phoneNumber
            }}</el-form-item>
        </el-form>
        <div class="btn">
          <div></div>
          <div class="confirm" @click="detailShow = false">取消</div>
        </div>
      </div>
    </van-popup>
    <Header :index="2"></Header>
    <div class="content">
      <div class="col-left">
        <div class="row-head" ref="sale">
          <div class="title">出售大厅</div>
          <el-button type="warning" @click="sale">发布出售</el-button>
        </div>
        <div class="row-head">
          <div class="screen-left">
            钢厂
            <el-select
                v-model="saleScreen.millName"
                placeholder="请选择"
                style="width: 100px"
                popper-class="screen"
                size="small"
                @change="event_saleScreen"
            >
              <el-option
                  v-for="(item, index) in gc"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
              ></el-option>
            </el-select>
            仓库
            <el-select
                v-model="saleScreen.warehouseName"
                placeholder="请选择"
                style="width: 100px"
                popper-class="screen"
                size="small"
                @change="event_saleScreen"
            >
              <el-option
                  v-for="(item, index) in ck"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
              ></el-option>
            </el-select>
            品名
            <el-select
                v-model="saleScreen.productName"
                placeholder="请选择"
                style="width: 100px"
                popper-class="screen"
                size="small"
                @change="event_saleScreen"
            >
              <el-option
                  v-for="(item, index) in pm"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
              ></el-option>
            </el-select>
            规格
            <el-select
                v-model="saleScreen.specs"
                placeholder="请选择"
                style="width: 100px"
                popper-class="screen"
                size="small"
                @change="event_saleScreen"
            >
              <el-option
                  v-for="(item, index) in gg"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
              ></el-option>
            </el-select>
            材质
            <el-select
                v-model="saleScreen.materialQuality"
                placeholder="请选择"
                style="width: 100px"
                popper-class="screen"
                size="small"
                @change="event_saleScreen"
            >
              <el-option
                  v-for="(item, index) in cz"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
              ></el-option>
            </el-select>
          </div>
          <div class="screen-right">
            <el-input
                size="small"
                placeholder="请输入需要查询的内容"
                style="width: 170px"
                v-model="saleScreen.keyWord"
            ></el-input>
            <el-button size="small" @click="event_saleScreen">搜索</el-button>
            <el-button size="small" @click="fresh('sale')"><i class="el-icon-refresh"></i></el-button>
          </div>
        </div>
        <el-table
            :data="saleData"
            max-height="620"
            border
            style="width: 100%; border: solid 1px #999999"
            :cell-style="{ borderColor: '#999999' }"
            :header-cell-style="{
            background: '#e7e7e7',
            borderColor: '#999999',
          }"
        >
          <el-table-column prop="millName" label="钢厂"> </el-table-column>
          <el-table-column prop="productName" label="品名"> </el-table-column>
          <el-table-column prop="specs" label="规格"> </el-table-column>
          <el-table-column prop="materialQuality" label="材质">
          </el-table-column>
          <el-table-column prop="warehouseName" label="仓库"> </el-table-column>
          <el-table-column
              prop="transactionPrice"
              label="出售价（元/吨）"
              width="150"
          >
          </el-table-column>
          <el-table-column prop="num" label="数量（吨）"> </el-table-column>
          <el-table-column prop="validityTime" label="有效期">
          </el-table-column>
          <el-table-column width="125" align="center">
            <template slot-scope="scope">
              <!--              <el-button type="primary" size="small">查看联系方式</el-button>-->
              <van-button
                  color="#009688"
                  size="small"
                  @click="showDetail(scope.row)"
              >查看联系方式</van-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="saleScreen.salePage"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="saleScreen.salePageSize"
            layout="prev, pager, next, jumper, total, sizes"
            :total="saleTotal"
        >
        </el-pagination>
        <div class="row-head" style="margin-top: 100px" ref="buy">
          <div class="title">求购大厅</div>
          <el-button type="warning" @click="buy">发布求购</el-button>
        </div>
        <div class="row-head">
          <div class="screen-left">
            钢厂
            <el-select
                v-model="buyScreen.millName"
                placeholder="请选择"
                style="width: 100px"
                popper-class="screen"
                size="small"
                @change="event_buyScreen"
            >
              <el-option
                  v-for="(item, index) in gc"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
              ></el-option>
            </el-select>
            仓库
            <el-select
                v-model="buyScreen.warehouseName"
                placeholder="请选择"
                style="width: 100px"
                popper-class="screen"
                size="small"
                @change="event_buyScreen"
            >
              <el-option
                  v-for="(item, index) in ck"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
              ></el-option>
            </el-select>
            品名
            <el-select
                v-model="buyScreen.productName"
                placeholder="请选择"
                style="width: 100px"
                popper-class="screen"
                size="small"
                @change="event_buyScreen"
            >
              <el-option
                  v-for="(item, index) in pm"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
              ></el-option>
            </el-select>
            规格
            <el-select
                v-model="buyScreen.specs"
                placeholder="请选择"
                style="width: 100px"
                popper-class="screen"
                size="small"
                @change="event_buyScreen"
            >
              <el-option
                  v-for="(item, index) in gg"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
              ></el-option>
            </el-select>
            材质
            <el-select
                v-model="buyScreen.materialQuality"
                placeholder="请选择"
                style="width: 100px"
                popper-class="screen"
                size="small"
                @change="event_buyScreen"
            >
              <el-option
                  v-for="(item, index) in cz"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
              ></el-option>
            </el-select>
          </div>
          <div class="screen-right">
            <el-input
                size="small"
                placeholder="请输入需要查询的内容"
                style="width: 170px"
                v-model="buyScreen.keyWord"
            ></el-input>
            <el-button size="small" @click="event_buyScreen">搜索</el-button>
            <el-button size="small" @click="fresh('buy')"><i class="el-icon-refresh"></i></el-button>
          </div>
        </div>
        <el-table
            :data="buyData"
            max-height="620"
            border
            style="width: 100%; border: solid 1px #999999"
            :cell-style="{ borderColor: '#999999' }"
            :header-cell-style="{
            background: '#e7e7e7',
            borderColor: '#999999',
          }"
        >
          <el-table-column prop="millName" label="钢厂"> </el-table-column>
          <el-table-column prop="productName" label="品名"> </el-table-column>
          <el-table-column prop="specs" label="规格"> </el-table-column>
          <el-table-column prop="materialQuality" label="材质">
          </el-table-column>
          <el-table-column prop="warehouseName" label="仓库"> </el-table-column>
          <el-table-column
              prop="transactionPrice"
              label="求购价（元/吨）"
              width="150"
          >
          </el-table-column>
          <el-table-column prop="num" label="数量（吨）"> </el-table-column>
          <el-table-column prop="validityTime" label="有效期">
          </el-table-column>
          <el-table-column width="125" align="center">
            <template slot-scope="scope">
              <!--              <el-button type="primary" size="small">查看联系方式</el-button>-->
              <van-button
                  color="#009688"
                  size="small"
                  @click="showDetail(scope.row)"
              >查看联系方式</van-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            @size-change="buySizeChange"
            @current-change="buyCurrentChange"
            :current-page="buyScreen.buyPage"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="buyScreen.buyPageSize"
            layout="prev, pager, next, jumper, total, sizes"
            :total="buyTotal"
        >
        </el-pagination>
      </div>
      <div class="col-right">
        <img :src="advert.imgUrl" alt="" @click="openWindow(advert.linkUrl)" />
        <div class="right-text">
          <p @click="intoView('buy')">求购大厅</p>
          <p @click="intoView('sale')">出售大厅</p>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<!--index.vue创建时间：2021/11/5 15:50:52-->
<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      advert:{},
      gc: [],
      pm: [],
      cz: [],
      gg: [],
      ck: [],
      detail: {},
      detailShow: false,
      buyTotal: null,
      buyData: [],
      buyShow: false,
      buyScreen: {
        pageNum: 1,
        pageSize: 10,
        //交易类型
        transactionType: 1,
        //类型
        type:2,
        millName: "",
        warehouseName: "",
        productName: "",
        specs: "",
        materialQuality: "",
        keyWord:""
      },
      releaseBuy: {
        //钢厂名
        millName: "",
        //品名
        productName: "",
        //材质
        materialQuality: "",
        //规格
        specs: "",
        //仓库
        warehouseName: "",
        //数量
        num: "",
        //价格
        transactionPrice: "",
        //有效期
        validityTime: "",
        //姓名
        username: "",
        //公司名称
        companyName: "",
        //联系电话
        phoneNumber: "",
        //交易类型
        transactionType: 1,
        //是否含税
        isIncludedTax: 0,
        // 类型
        type:2
      },
      saleTotal: null,
      saleData: [],
      saleShow: false,
      saleScreen: {
        pageNum: 1,
        pageSize: 10,
        //交易类型
        transactionType: 2,
        //类型
        type:2,
        millName: "",
        warehouseName: "",
        productName: "",
        specs: "",
        materialQuality: "",
        keyWord:""
      },
      releaseSale: {
        //钢厂名
        millName: "",
        //品名
        productName: "",
        //材质
        materialQuality: "",
        //规格
        specs: "",
        //仓库
        warehouseName: "",
        //数量
        num: "",
        //价格
        transactionPrice: "",
        //有效期
        validityTime: "",
        //姓名
        username: "",
        //公司名称
        companyName: "",
        //联系电话
        phoneNumber: "",
        //交易类型
        transactionType: 2,
        //是否含税
        isIncludedTax: 0,
        // 类型
        type:2
      },
    };
  },
  async mounted() {
    await this.buyNetwork()
    this.gc = (await this.http("/web/dict/treebytype/1002")).data;
    this.pm = (await this.http("/web/dict/treebytype/1003")).data;
    this.cz = (await this.http("/web/dict/treebytype/1004")).data;
    this.gg = (await this.http("/web/dict/treebytype/1005")).data;
    this.ck = (await this.http("/web/dict/treebytype/1006")).data;
    await this.saleNetwork()
    this.http('/web/advertisement/list',{type:2}).then(res=>{
      this.advert=res.data[0]
    })
  },
  methods: {
    buyNetwork() {
      this.http("/web/buysell/page", this.buyScreen).then((res) => {
        this.buyData = res.data.rows;
        this.buyTotal = res.data.total;
      });
    },
    saleNetwork() {
      this.http("/web/buysell/page", this.saleScreen).then((res) => {
        this.saleData = res.data.rows;
        this.saleTotal = res.data.total;
      });
    },
    event_saleScreen(){
      this.saleNetwork()
    },
    event_buyScreen(){
      this.buyNetwork()
    },
    handleSizeChange(val) {
      this.saleScreen.pageSize = val;
      this.saleNetwork();
    },
    handleCurrentChange(val) {
      this.saleScreen.pageNum = val;
      this.saleNetwork();
    },
    buySizeChange(val) {
      this.buyScreen.pageSize = val;
      this.buyNetwork();
    },
    buyCurrentChange(val) {
      this.buyScreen.pageNum = val;
      this.buyNetwork();
    },
    popup_cancel() {
      this.saleShow = false;
      this.buyShow = false;
    },
    confirmSale() {
      if (!this.releaseSale.millName) {
        this.$message("请选择或输入钢厂名");
        return;
      }
      if (!this.releaseSale.productName) {
        this.$message("请选择或输入品名");
        return;
      }
      if (!this.releaseSale.materialQuality) {
        this.$message("请选择或输入材质");
        return;
      }
      if (!this.releaseSale.specs) {
        this.$message("请选择或输入规格");
        return;
      }
      if (!this.releaseSale.warehouseName) {
        this.$message("请选择或输入仓库");
        return;
      }
      if (!this.releaseSale.num) {
        this.$message("请输入数量");
        return;
      }
      if (!this.releaseSale.transactionPrice) {
        this.$message("请输入价格");
        return;
      }
      this.http("/web/buysell/add", this.releaseSale, "post").then((res) => {
        if (res.code === 200) {
          let obj = this.releaseSale;
          for (let key in obj) {
            obj[key] = "";
          }
          //交易类型
          obj.transactionType = 2;
          //是否含税
          obj.isIncludedTax = 0;
          //类型
          obj.type=2
          this.releaseSale = obj;
          this.$message.success("发布成功");
          this.saleShow = false;
          this.saleNetwork();
        }
      });
    },
    popup_confirm() {
      if (!this.releaseBuy.millName) {
        this.$message("请选择或输入钢厂名");
        return;
      }
      if (!this.releaseBuy.productName) {
        this.$message("请选择或输入品名");
        return;
      }
      if (!this.releaseBuy.materialQuality) {
        this.$message("请选择或输入材质");
        return;
      }
      if (!this.releaseBuy.specs) {
        this.$message("请选择或输入规格");
        return;
      }
      if (!this.releaseBuy.warehouseName) {
        this.$message("请选择或输入仓库");
        return;
      }
      if (!this.releaseBuy.num) {
        this.$message("请输入数量");
        return;
      }
      if (!this.releaseBuy.transactionPrice) {
        this.$message("请输入价格");
        return;
      }
      this.http("/web/buysell/add", this.releaseBuy, "post").then((res) => {
        if (res.code === 200) {
          let obj = this.releaseBuy;
          for (let key in obj) {
            obj[key] = "";
          }
          //交易类型
          obj.transactionType = 1;
          //是否含税
          obj.isIncludedTax = 0;
          //类型
          obj.type=2
          this.releaseBuy = obj;
          this.$message.success("发布成功");
          this.buyShow = false;
          this.buyNetwork();
        }
      });
    },
    async buy() {
      let flag = await this.http("/web/user/checkuserinfocomplete").then(
          (res) => {
            if (!res.data) {
              this.$store.commit("open", true);
              return false;
            }
            if (res.data) {
              return true;
            }
          }
      );
      if (!flag) {
        return;
      }
      let userInfo = (await this.http("/web/user/getcurrentuserinfo")).data;
      this.releaseBuy.username = userInfo.username;
      this.releaseBuy.companyName = userInfo.companyName;
      this.releaseBuy.phoneNumber = userInfo.phoneNumber;
      this.buyShow = true;
    },
    async sale() {
      let flag = await this.http("/web/user/checkuserinfocomplete").then(
          (res) => {
            if (!res.data) {
              this.$store.commit("open", true);
              return false;
            }
            if (res.data) {
              return true;
            }
          }
      );
      if (!flag) {
        return;
      }
      let userInfo = (await this.http("/web/user/getcurrentuserinfo")).data;
      this.releaseSale.username = userInfo.username;
      this.releaseSale.companyName = userInfo.companyName;
      this.releaseSale.phoneNumber = userInfo.phoneNumber;
      this.saleShow = true;
    },
    showDetail(item) {
      if(!sessionStorage.getItem("token")){
        this.$message.warning("您还没有登录，请先登录");
        return
      }else{
        this.http(`/web/buysell/getbyid/${item.id}`)
      }
      this.detail = item;
      this.detailShow = true;
    },
    intoView(el_id){
      this.$refs[el_id].scrollIntoView({
        behavior:"smooth"
      })
    },
    openWindow(url){
      if(url){
        window.open(url,"blank")
      }
    },
    fresh(type){
      if(type==='sale'){
        this.saleScreen={
          pageNum: 1,
          pageSize: 10,
          //交易类型
          transactionType: 2,
          //类型
          type:2,
          millName: "",
          warehouseName: "",
          productName: "",
          specs: "",
          materialQuality: "",
          keyWord:""
        };
        this.saleNetwork()
      }else{
        this.buyScreen={
          pageNum: 1,
          pageSize: 10,
          //交易类型
          transactionType: 1,
          //类型
          type:2,
          millName: "",
          warehouseName: "",
          productName: "",
          specs: "",
          materialQuality: "",
          keyWord:""
        };
        this.buyNetwork()
      }
    }
  },
};
</script>

<style scoped lang="scss">
.container {
  > .content {
    width: 1200px;
    display: flex;
    margin: 0 auto 50px;
    justify-content: space-between;
    position: relative;
    > .col-left {
      width: 1000px;
      margin-top: 50px;
      > .row-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > .title {
          font-size: 24px;
          font-weight: 400;
          color: #1b294e;
          padding-left: 10px;
          border-left: solid 10px #1b294e;
        }
        > .screen {
          color: #00a0e9;
        }
        > .screen-left {
          display: flex;
          font-size: 14px;
          align-items: center;
          width: 70%;
          justify-content: space-between;
          margin: 30px 0;
        }
      }
      > .row-title {
        text-align: center;
        margin-top: 50px;
        font-size: 24px;
      }
      > .time {
        text-align: right;
        font-size: 18px;
        line-height: 30px;
      }
      > .welcome {
        font-size: 18px;
      }
      > .row-content {
        font-size: 18px;
        line-height: 40px;
        margin-top: 10px;
      }
    }
    > .col-right {
      width: 150px;
      height: auto;
      display: flex;
      flex-direction: column;
      > img {
        width: 150px;
        height: 296px;
        position: fixed;
        top: 200px;
        cursor: pointer;
      }
      > .right-text {
        width: 150px;
        height: 198px;
        background: #ffffff;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        text-align: center;
        position: fixed;
        top: 600px;
        >p{
          cursor: pointer
        }
      }
    }
  }
}
.popup-content {
  width: 900px;
  padding-bottom: 50px;
  user-select: none;
  > .popup-header {
    width: calc(100% - 20px);
    height: 59px;
    background: #1b294e;
    line-height: 59px;
    font-size: 24px;
    font-weight: 400;
    color: #ffffff;
    padding-left: 20px;
  }
  > .list {
    display: flex;
    justify-content: center;
    margin: 30px 0;
  }
  > .popup-btns {
    display: flex;
    justify-content: space-between;
    > .btn {
      display: flex;
      > .popup-cancel {
        width: 200px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background: #f6f6f6;
        border-radius: 5px;
        font-size: 24px;
        font-weight: 400;
        color: #999999;
        cursor: pointer;
      }

      > .popup-confirm {
        width: 200px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background: #00b76a;
        border-radius: 5px;
        font-size: 24px;
        font-weight: 400;
        color: #ffffff;
        margin: 0 50px;
        cursor: pointer;
      }
    }
  }
}
.detail-con {
  width: 600px;
  height: 700px;
  > .head {
    height: 59px;
    line-height: 59px;
    padding-left: 24px;
    background: #1b294e;
    font-size: 24px;
    font-weight: 400;
    color: #ffffff;
  }
  > .btn {
    display: flex;
    justify-content: space-around;
    > .confirm {
      width: 150px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background: #00b76a;
      border-radius: 5px;
      font-size: 24px;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
      user-select: none;
    }
  }
}
</style>
