var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('Header',{attrs:{"index":9}}),_c('img',{attrs:{"src":require("@/assets/partner/head.png"),"alt":""}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"col-left"},[_c('div',{staticClass:"change"},_vm._l((_vm.changeData),function(item,index){return _c('div',{key:index,staticClass:"text"},[_c('div',{class:_vm.changeCurrent === index ? 'activeText' : '',on:{"click":function($event){return _vm.change(item.id, index)}}},[_vm._v(" "+_vm._s(item.text)+" "),(_vm.changeCurrent === index)?_c('div',{staticClass:"line"}):_vm._e()]),(index !== _vm.changeData.length - 1)?_c('span',[_vm._v("|")]):_vm._e()])}),0),_c('p',[_vm._v("合作伙伴")]),_vm._l((_vm.partnerData),function(item,index){return _c('div',{key:index,staticClass:"list"},[_c('img',{attrs:{"src":item.coverPhotoUrl,"alt":""}}),_c('div',{staticClass:"col-right"},[_c('p',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.brief))]),_c('p',[_vm._v(_vm._s(item.publishTime))]),_c('div',{staticClass:"row-bottom"},[_c('p',{on:{"click":function($event){return _vm.$router.push({
            path:'/detail',
            query:{id:item.id,index:9}
            })}}},[_vm._v("查看详情")]),_c('div',[_c('img',{attrs:{"src":_vm.id === 1
                    ? require('@/assets/partner/gangmao.png')
                    : _vm.id === 2
                    ? require('@/assets/partner/yunshu.png')
                    : _vm.id === 3
                    ? require('@/assets/partner/jianzhu.png')
                    : _vm.id === 4
                    ? require('@/assets/partner/jiagong.png')
                    : _vm.id === 5
                    ? require('@/assets/partner/cupin.png')
                    : '',"alt":""}}),_vm._v(" "+_vm._s(_vm.id === 1 ? "钢贸行业" : _vm.id === 2 ? "运输行业" : _vm.id === 3 ? "建筑行业" : _vm.id === 4 ? "加工行业" : _vm.id === 5 ? "促品展示" : "")+" ")])])])])}),_c('div',{staticStyle:{"text-align":"center","margin":"50px 0"}},[_c('el-pagination',{attrs:{"background":"","current-page":_vm.page,"page-sizes":[5, 10, 15, 20],"page-size":_vm.pageSize,"layout":"prev, pager, next, jumper, total, sizes","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],2),_c('div',{staticClass:"col-right"},[_c('img',{attrs:{"src":_vm.advert.imgUrl,"alt":""},on:{"click":function($event){return _vm.openWindow(_vm.advert.linkUrl)}}}),_c('div',{staticClass:"right-text"},[_c('div',{on:{"click":function($event){return _vm.change(1, 0)}}},[_c('img',{attrs:{"src":require("@/assets/partner/gangmao.png"),"alt":""}}),_vm._v("钢贸行业")]),_c('div',{on:{"click":function($event){return _vm.change(2, 1)}}},[_c('img',{attrs:{"src":require("@/assets/partner/yunshu.png"),"alt":""}}),_vm._v("运输行业")]),_c('div',{on:{"click":function($event){return _vm.change(3, 2)}}},[_c('img',{attrs:{"src":require("@/assets/partner/jianzhu.png"),"alt":""}}),_vm._v("建筑行业")]),_c('div',{on:{"click":function($event){return _vm.change(4, 3)}}},[_c('img',{attrs:{"src":require("@/assets/partner/jiagong.png"),"alt":""}}),_vm._v("加工行业")]),_c('div',{on:{"click":function($event){return _vm.change(5, 4)}}},[_c('img',{attrs:{"src":require("@/assets/partner/cupin.png"),"alt":""}}),_vm._v("促品展示")])])])]),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }