<template>
  <div class="container">
    <van-popup
      v-model="sourceShow"
      get-container="body"
      :lazy-render="false"
      :close-on-click-overlay="false"
      z-index="99"
    >
      <div class="popup-content">
        <div class="popup-header">{{ edit ? "查看联系方式" : "货源发布" }}</div>
        <el-form
          :model="formData"
          label-width="100px"
          style="margin-left: 83px; margin-top: 20px"
        >
          <el-form-item label="装货地">
            <el-cascader
              placeholder="请选择省市区"
              :options="options"
              separator="-"
              v-model="selectedOptions"
              @change="handleChange"
              size="small"
              :disabled="edit"
            ></el-cascader>
            <el-input
              v-model="formData.placeA"
              style="width: 180px; margin-left: 10px"
              placeholder="请输入详细地址"
              size="small"
              :disabled="edit"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="卸货地">
            <el-cascader
              placeholder="请选择省市区"
              :options="options"
              separator="-"
              v-model="selectedB"
              @change="ChangeB"
              size="small"
              :disabled="edit"
            ></el-cascader>
            <el-input
              v-model="formData.placeB"
              style="width: 180px; margin-left: 10px"
              placeholder="请输入详细地址"
              size="small"
              :disabled="edit"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-form
              :inline="true"
              label-width="100px"
              style="margin-left: -100px"
            >
              <el-form-item label="货物名称">
                <el-select
                  v-model="formData.productName"
                  placeholder="请选择"
                  size="small"
                  style="width: 205px"
                  :disabled="edit"
                >
                  <el-option
                    v-for="(item, index) in pm"
                    :key="index"
                    :label="item.label"
                    :value="item.label"
                  ></el-option>
                </el-select>
                <el-input
                  v-model="formData.productName"
                  placeholder="或手动填写货物名称"
                  style="width: 205px; margin-left: 14px"
                  size="small"
                  :disabled="edit"
                ></el-input>
              </el-form-item>
              <el-form-item label="重量">
                <el-input
                  v-model="weightTon"
                  placeholder="请选填货物重量（单位：吨）"
                  style="width: 205px; margin-left: 14px"
                  size="small"
                  :disabled="edit"
                ></el-input>
                <el-input
                  v-model="weightPower"
                  placeholder="请选填货物重量（单位：方）"
                  style="width: 205px; margin-left: 14px"
                  size="small"
                  :disabled="edit"
                ></el-input>
              </el-form-item>
            </el-form>
          </el-form-item>
          <el-form-item label="车长（米）">
            <div style="display: flex">
              <div
                :class="item.checked ? 'activeBox' : 'nomalBox'"
                v-for="(item, index) in ccData"
                :key="index"
                @click="edit ? '' : ccSelect(index)"
              >
                {{ item.label }}米
                <img
                  v-if="item.checked"
                  src="@/assets/home/icontest.png"
                  alt=""
                />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-form inline label-width="100px" style="margin-left: -100px">
              <el-form-item label="车型">
                <div style="display: flex">
                  <div
                    :class="item.checked ? 'activeBox' : 'nomalBox'"
                    v-for="(item, index) in cxData"
                    :key="index"
                    @click="edit ? '' : cxSelect(index)"
                  >
                    {{ item.text }}
                    <img
                      v-if="item.checked"
                      src="@/assets/home/icontest.png"
                      alt=""
                    />
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="方式" style="margin-left: 160px">
                <div style="display: flex">
                  <div
                    :class="fsCurrent === index ? 'activeBox' : 'nomalBox'"
                    v-for="(item, index) in fsData"
                    :key="index"
                    @click="edit ? '' : fsSelect(index)"
                  >
                    {{ item.text }}
                    <img
                      v-if="fsCurrent === index"
                      src="@/assets/home/icontest.png"
                      alt=""
                    />
                  </div>
                </div>
              </el-form-item>
            </el-form>
          </el-form-item>
          <el-form-item>
            <el-form label-width="100px" inline style="margin-left: -100px">
              <el-form-item label="备注">
                <el-input
                  v-model="formData.remark"
                  placeholder="请输入备注"
                  style="width: 424px"
                  size="small"
                  :disabled="edit"
                ></el-input>
              </el-form-item>
              <el-form-item label="装货时间">
                <el-date-picker
                  v-model="formData.loadingTime"
                  type="date"
                  size="small"
                  placeholder="选择装货日期（默认今天）"
                  value-format="yyyy.MM.dd"
                  format="yyyy.MM.dd"
                  :disabled="edit"
                >
                </el-date-picker>
                <el-select
                  v-model="formData.loadingTimeDesc"
                  placeholder="请选择"
                  size="small"
                  style="width: 195px; margin-left: 14px"
                  :disabled="edit"
                >
                  <el-option
                    :label="item.label"
                    :value="item.label"
                    v-for="(item, index) in lodingTime"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </el-form-item>
          <el-form-item label="我的出价">
            <el-input-number
              :controls="false"
              :precision="2"
              v-model="priceTon"
              size="small"
              style="width: 120px"
              :disabled="edit"
            ></el-input-number
            >元 / 吨
            <el-input-number
              :controls="false"
              :precision="2"
              v-model="pricePower"
              size="small"
              style="width: 120px"
              :disabled="edit"
            ></el-input-number
            >元 / 趟
          </el-form-item>
          <el-form-item>
            <el-form label-width="100px" inline style="margin-left: -100px">
              <el-form-item label="姓名">
                <el-input
                  v-model="formData.username"
                  placeholder="请输入姓名"
                  style="width: 320px"
                  size="small"
                  disabled
                ></el-input>
              </el-form-item>
              <el-form-item label="公司" style="margin-left: 105px">
                <el-input
                  v-model="formData.companyName"
                  placeholder="请输入公司名称"
                  style="width: 320px"
                  size="small"
                  disabled
                ></el-input>
              </el-form-item>
            </el-form>
          </el-form-item>
          <el-form-item label="电话">
            <el-input
              v-model="formData.phoneNumber"
              placeholder="请输入电话号码"
              style="width: 320px"
              size="small"
              disabled
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="popup-btns">
          <div></div>
          <div style="display: flex">
            <div class="popup-cancel" @click="popup_cancel">取消</div>
            <div
              class="popup-confirm"
              @click="saveBusiness(formData.id)"
              v-if="edit"
            >
              发起交易
            </div>
            <div class="popup-confirm" @click="popup_confirm" v-if="!edit">
              发布
            </div>
          </div>
        </div>
      </div>
    </van-popup>

    <van-popup
      v-model="transportShow"
      get-container="body"
      :lazy-render="false"
      :close-on-click-overlay="false"
      z-index="99"
    >
      <div class="TS_popup">
        <div class="row_head">
          {{ edit ? "查看联系方式" : "运力发布"
          }}<van-icon
            name="cross"
            size="25px"
            style="cursor: pointer"
            @click="popup_cancel"
          />
        </div>
        <div class="row-content">
          <el-form label-width="100px" style="padding: 30px 0 0 50px">
            <el-form-item label="主运货物">
              <el-input
                v-model="TsFormData.mainProductName"
                placeholder="请输入主运货物，多个可用；隔开，例如（盘元；螺纹钢）"
                style="width: 60%"
                :disabled="edit"
              ></el-input>
            </el-form-item>
            <el-form-item label="车辆信息">
              <el-input
                placeholder="请输入车辆信息，例如（高栏：2辆；平板：2辆）"
                v-model="TsFormData.carType"
                style="width: 60%"
                :disabled="edit"
              ></el-input>
            </el-form-item>
            <el-form-item label="运输路线">
              <el-input
                placeholder="请输入始发地"
                v-model="TsFormData.transportStartAddress"
                style="width: 20%"
                :disabled="edit"
              ></el-input
              >&emsp;到&emsp;<el-input
                placeholder="请输入终点"
                v-model="TsFormData.transportEndAddress"
                style="width: 20%"
                :disabled="edit"
              ></el-input>
            </el-form-item>
            <el-form-item label="简单介绍">
              <el-input
                placeholder="请输入简单介绍"
                v-model="TsFormData.remark"
                style="width: 45%"
                :disabled="edit"
              ></el-input>
            </el-form-item>
            <el-form-item label="姓名：">
              <el-input
                placeholder="请输入姓名"
                v-model="TsFormData.username"
                style="width: 45%"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="公司：">
              <el-input
                placeholder="请输入公司名称"
                v-model="TsFormData.companyName"
                style="width: 45%"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="电话">
              <el-input
                placeholder="请输入联系电话"
                v-model="TsFormData.phoneNumber"
                style="width: 45%"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item>
              <van-button
                color="#009688"
                size="large"
                style="width: 100px"
                @click="addTransport"
                v-if="!edit"
                >立即发布</van-button
              >
              <van-button
                color="#009688"
                size="large"
                style="width: 100px"
                @click="tsSavebusiness(TsFormData.id)"
                v-if="edit"
                >发起交易</van-button
              >
              <van-button
                color="#333333"
                size="large"
                style="width: 80px; margin-left: 20px"
                @click="popup_cancel"
                >取消</van-button
              ></el-form-item
            >
          </el-form>
        </div>
      </div>
    </van-popup>
    <Header :index="4"></Header>
    <div class="content">
      <div class="row-head">
        <div class="title">货源信息</div>
        <el-button type="warning" @click="add">货源发布</el-button>
      </div>
      <div class="row-head">
        <div class="screen-left">
          装货地
          <el-cascader
            placeholder="请选择省市区"
            :options="options"
            separator="-"
            v-model="screenOptions"
            @change="screenHandleChange"
            size="small"
            :disabled="edit"
            style="width: 100px"
          ></el-cascader>
          卸货地
          <el-cascader
            placeholder="请选择省市区"
            :options="options"
            separator="-"
            v-model="screenOptionsB"
            @change="screenHandleChangeB"
            size="small"
            :disabled="edit"
            style="width: 100px"
          ></el-cascader>
          车长
          <el-select
            v-model="buyScreen.carLength"
            placeholder="请选择"
            style="width: 100px"
            popper-class="screen"
            size="small"
            @change="buyScreen_event"
          >
            <el-option
              v-for="(item, index) in ccData"
              :key="index"
              :label="item.label + '米'"
              :value="item.label"
            ></el-option>
          </el-select>
          方式
          <el-select
            v-model="buyScreen.transportWay"
            placeholder="请选择"
            style="width: 100px"
            popper-class="screen"
            size="small"
            @change="buyScreen_event"
          >
            <el-option
              v-for="(item, index) in fsData"
              :key="index"
              :label="item.text"
              :value="item.text"
            ></el-option>
          </el-select>
        </div>
        <div class="screen-right">
          <el-input
            size="small"
            placeholder="请输入需要查询的内容"
            style="width: 170px"
            v-model="buyScreen.keyWord"
          ></el-input>
          <el-button size="small" @click="buyScreen_event">搜索</el-button>
          <el-button size="small" @click="fresh"
            ><i class="el-icon-refresh"></i
          ></el-button>
        </div>
      </div>
      <el-table
        :data="tableData"
        max-height="620"
        border
        style="width: 100%; border: solid 1px #999999"
        :cell-style="{ borderColor: '#999999' }"
        :header-cell-style="{
          background: '#e7e7e7',
          borderColor: '#999999',
        }"
      >
        <el-table-column prop="productName" label="品名"> </el-table-column>
        <el-table-column label="装货地" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{
              scope.row.placeAProvince +
              (scope.row.placeACity ? scope.row.placeACity : "") +
              (scope.row.placeACounty ? scope.row.placeACounty : "") +
              (scope.row.placeA ? scope.row.placeA : "")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="卸货地" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{
              scope.row.placeBProvince +
              (scope.row.placeBCity ? scope.row.placeBCity : "") +
              (scope.row.placeBCounty ? scope.row.placeBCounty : "") +
              (scope.row.placeB ? scope.row.placeB : "")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="价格"> </el-table-column>
        <el-table-column prop="carLength" label="车长（米）"> </el-table-column>
        <el-table-column prop="carType" label="类型" width="100">
        </el-table-column>
        <el-table-column prop="transportWay" label="方式" width="100">
        </el-table-column>
        <el-table-column prop="transportWeight" label="重量" width="100">
        </el-table-column>
        <el-table-column label="装货时间">
          <template slot-scope="scope">
            {{ scope.row.loadingTime + scope.row.loadingTimeDesc }}
          </template>
        </el-table-column>
        <el-table-column width="125" align="center">
          <template slot-scope="scope">
            <!--              <el-button type="primary" size="small">查看联系方式</el-button>-->
            <van-button
              color="#009688"
              size="small"
              @click="addAgain(scope.row)"
              >查看联系方式</van-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="buySizeChange"
        @current-change="buyCurrentChange"
        :current-page="buyScreen.pageNum"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="buyScreen.pageSize"
        layout="prev, pager, next, jumper, total, sizes"
        :total="buyTotal"
      >
      </el-pagination>
      <div class="row-head" style="margin-top: 100px">
        <div class="title">运力信息</div>
        <el-button type="warning" @click="transport">运力发布</el-button>
      </div>
      <div class="row-head">
        <div class="screen-left"></div>
        <div class="screen-right">
          <el-input
            size="small"
            placeholder="请输入需要查询的内容"
            style="width: 170px"
            v-model="tsScreen.keyWord"
          ></el-input>
          <el-button size="small" @click="tsScreen_event">搜索</el-button>
        </div>
      </div>
      <el-table
        :data="sportData"
        max-height="620"
        border
        style="width: 100%; border: solid 1px #999999"
        :cell-style="{ borderColor: '#999999' }"
        :header-cell-style="{
          background: '#e7e7e7',
          borderColor: '#999999',
        }"
      >
        <el-table-column prop="mainProductName" label="主运货物">
        </el-table-column>
        <el-table-column label="运输线路">
          <template slot-scope="scope">
            <span>{{
              scope.row.transportStartAddress +
              "-" +
              scope.row.transportEndAddress
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="carType" label="车辆信息"> </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
        <el-table-column width="125" align="center">
          <template slot-scope="scope">
            <!--              <el-button type="primary" size="small">查看联系方式</el-button>-->
            <van-button
              color="#009688"
              size="small"
              @click="showTransport(scope.row)"
              >查看联系方式</van-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="tsScreen.pageNum"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="tsScreen.pageSize"
        layout="prev, pager, next, jumper, total, sizes"
        :total="tsTotal"
      >
      </el-pagination>
    </div>
    <Footer></Footer>
    <el-dialog
      :title="ruleTitle"
      :show-close="false"
      :visible.sync="ruleShow"
      @close="onClose"
      width="35%"
    >
      <div v-html="ruleContent"></div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="elConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<!--index.vue创建时间：2021/11/8 10:27:55-->
<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import {
  CodeToText,
  regionDataPlus,
  TextToCode,
} from "element-china-area-data";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      ruleTitle: "",
      id: "",
      ruleShow: false,
      ruleContent: "",
      ruleType: 2,
      tsType:false,
      screen: "",
      tableData: [],
      sportData: [],
      fsCurrent: null,
      edit: false,
      //货物名称
      pm: [],
      //地区
      options: [],
      //编辑弹窗
      sourceShow: false,
      //发货地
      selectedOptions: "",
      //卸货地
      selectedB: "",
      //重量（吨）
      weightTon: "",
      //重量（方）
      weightPower: "",
      // 价格（吨）
      priceTon: "",
      //价格（方）
      pricePower: "",
      //表单数据
      formData: {},
      buyTotal: null,
      ccData: [],
      cxData: [
        { text: "平板", checked: false },
        { text: "高栏", checked: false },
      ],
      fsData: [
        { text: "拼车", checked: false },
        { text: "整车", checked: false },
      ],
      lodingTime: [],
      screenOptions: "",
      screenOptionsB: "",
      buyScreen: {
        pageNum: 1,
        pageSize: 10,
        carLength: "",
        transportWay: "",
        keyWord: "",
      },
      transportShow: false,
      TsFormData: {},
      tsScreen: {
        pageNum: 1,
        pageSize: 10,
        keyWord: "",
      },
      tsTotal: null,
    };
  },
  async mounted() {
    this.pm = (await this.http("/web/dict/treebytype/1003")).data;
    this.lodingTime = (await this.http("/web/dict/treebytype/1019")).data;
    let arr = (await this.http("/web/dict/treebytype/1008")).data;
    this.ccData = arr.map((item) => {
      return {
        ...item,
        checked: false,
      };
    });
    await this.buyNetwork();
    await this.tsNetwork();
    let array = [];
    regionDataPlus.map((item) => {
      if (
        item.label.search("四川") !== -1 ||
        item.label.search("重庆") !== -1 ||
        item.label.search("贵州") !== -1 ||
        item.label.search("陕西") !== -1 ||
        item.label.search("山西") !== -1 ||
        item.label.search("河南") !== -1 ||
        item.label.search("云南") !== -1 ||
        item.label.search("甘肃") !== -1 ||
        item.label.search("西藏") !== -1
      ) {
        array.push(item);
      }
    });
    this.options = array;
  },
  methods: {
    buyNetwork() {
      this.http("/web/productsource/pageinhall", this.buyScreen).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.rows;
          this.buyTotal = res.data.total;
        }
      });
    },
    tsNetwork() {
      this.http("/web/transportability/pageinhall", this.tsScreen).then(
        (res) => {
          if (res.code === 200) {
            this.sportData = res.data.rows;
            this.tsTotal = res.data.total;
          }
        }
      );
    },
    buyScreen_event() {
      this.buyNetwork();
    },
    tsScreen_event() {
      this.tsNetwork();
    },
    buySizeChange(val) {
      this.buyScreen.pageSize = val;
      this.buyNetwork();
    },
    buyCurrentChange(val) {
      this.buyScreen.pageNum = val;
      this.buyNetwork();
    },
    handleSizeChange(val) {
      this.tsScreen.pageSize = val;
      this.tsNetwork();
    },
    handleCurrentChange(val) {
      this.tsScreen.pageNum = val;
      this.tsNetwork();
    },
    popup_cancel() {
      this.saleShow = false;
      this.buyShow = false;
      this.sourceShow = false;
      this.transportShow = false;
      this.edit = false;
      this.TsFormData = {};
    },
    async transport() {
      let flag = await this.http("/web/user/checkuserinfocomplete").then(
        (res) => {
          if (!res.data) {
            this.$store.commit("open", true);
            return false;
          }
          if (res.data) {
            return true;
          }
        }
      );
      if (!flag) {
        return;
      }
      let userInfo = (await this.http("/web/user/getcurrentuserinfo")).data;
      this.TsFormData.username = userInfo.username;
      this.TsFormData.companyName = userInfo.companyName;
      this.TsFormData.phoneNumber = userInfo.phoneNumber;
      this.transportShow = true;
    },
    showTransport(obj) {
      if (!sessionStorage.getItem("token")) {
        this.$message.warning("您还没有登录，请先登录");
        return;
      } else {
        this.http(`/web/transportability/getbyid/${obj.id}`);
      }
      this.TsFormData = obj;
      this.edit = true;
      this.transportShow = true;
    },
    popup_confirm() {
      if (this.weightTon) {
        this.formData.transportWeight = this.weightTon + "吨";
      }
      if (this.weightPower) {
        this.formData.transportWeight = this.weightPower + "方";
      }
      if (this.weightTon && this.weightPower) {
        this.formData.transportWeight =
          this.weightTon + "吨或" + this.weightPower + "方";
      }
      if (this.priceTon) {
        this.formData.price = this.priceTon + "元/吨";
      }
      if (this.pricePower) {
        this.formData.price = this.pricePower + "元/趟";
      }
      if (this.priceTon && this.pricePower) {
        this.formData.price =
          this.priceTon + "元/吨或" + this.pricePower + "元/趟";
      }
      if (
        !this.formData.placeAProvince &&
        !this.formData.placeACity &&
        !this.formData.placeACounty
      ) {
        this.$message("请选择装货地");
        return;
      }
      if (
        !this.formData.placeBProvince &&
        !this.formData.placeBCity &&
        !this.formData.placeBCounty
      ) {
        this.$message("请选择卸货地");
        return;
      }
      if (!this.formData.productName) {
        this.$message("请选择或输入货物名称");
        return;
      }
      if (!this.formData.carLength) {
        this.$message("请选择车长");
        return;
      }
      if (!this.formData.carType) {
        this.$message("请选择车型");
        return;
      }
      if (!this.formData.transportWay) {
        this.$message("请选择方式");
        return;
      }
      if (!this.formData.price) {
        this.$message("请输入您的出价");
        return;
      }
      this.formData.id = null;
      this.formData.auditStatus = null;
      this.http("/web/productsource/add", this.formData, "post").then(
        async (res) => {
          if (res.code === 200) {
            this.$message.success("发布成功");
            this.sourceShow = false;
            this.formData = {};
            this.buyNetwork();
          }
        }
      );
    },
    async buy() {
      let userInfo = (await this.http("/web/user/getcurrentuserinfo")).data;
      this.formData.username = userInfo.username;
      this.formData.companyName = userInfo.companyName;
      this.formData.phoneNumber = userInfo.phoneNumber;
      this.buyShow = true;
    },
    async add() {
      let flag = await this.http("/web/user/checkuserinfocomplete").then(
        (res) => {
          if (!res.data) {
            this.$store.commit("open", true);
            return false;
          }
          if (res.data) {
            return true;
          }
        }
      );
      if (!flag) {
        return;
      }
      this.ruleShow = true;
      this.ruleTitle = "担保协议";
      this.ruleContent = (
        await this.http(`/web/introduce/getbyid/18`)
      ).data.contentStr;
      this.ruleType = 2;
    },
    addAgain(obj) {
      if (!sessionStorage.getItem("token")) {
        this.$message.warning("您还没有登录，请先登录");
        return;
      } else {
        this.http(`/web/productsource/getbyid/${obj.id}`);
      }
      this.ccData.map((item) => {
        item.checked = false;
      });
      this.cxData = [
        { text: "平板", checked: false },
        { text: "高栏", checked: false },
      ];
      this.fsData = [
        { text: "拼车", checked: false },
        { text: "整车", checked: false },
      ];
      this.formData = obj;
      this.selectedOptions = this.getCityCode(
        obj.placeAProvince + "/" + obj.placeACity + "/" + obj.placeACounty
      );
      this.selectedB = this.getCityCode(
        obj.placeBProvince + "/" + obj.placeBCity + "/" + obj.placeBCounty
      );
      let arr = obj.transportWeight.split("或");
      if (arr.length === 1) {
        this.weightTon = arr[0].slice(0, arr[0].length - 1);
        this.weightPower = "";
      } else {
        this.weightTon = arr[0].slice(0, arr[0].length - 1);
        this.weightPower = arr[1].slice(0, arr[1].length - 1);
      }
      let priceArr = obj.price.split("或");
      if (priceArr.length === 1) {
        this.priceTon = priceArr[0].slice(0, priceArr[0].length - 3);
        this.pricePower = "";
      } else {
        this.priceTon = priceArr[0].slice(0, priceArr[0].length - 3);
        this.pricePower = priceArr[1].slice(0, priceArr[1].length - 3);
      }
      let carArr = obj.carLength.split("/");
      this.ccData.map((item) => {
        carArr.map((i) => {
          if (i === item.label) {
            item.checked = true;
          }
        });
      });
      let typeArr = obj.carType.split("/");
      this.cxData.map((item) => {
        typeArr.map((i) => {
          if (i === item.text) {
            item.checked = true;
          }
        });
      });
      let transportArr = obj.transportWay;
      this.fsData.map((item, index) => {
        if (transportArr === item.text) {
          this.fsCurrent = index;
        }
      });
      this.sourceShow = true;
      this.edit = true;
    },
    addTransport() {
      if (!this.TsFormData.mainProductName) {
        this.$message("请输入主运货物");
        return;
      }
      if (!this.TsFormData.carType) {
        this.$message("请输入车辆信息");
        return;
      }
      if (!this.TsFormData.transportStartAddress) {
        this.$message("请输入始发地");
        return;
      }
      if (!this.TsFormData.transportEndAddress) {
        this.$message("请输入终点");
        return;
      }
      this.http("/web/transportability/add", this.TsFormData, "post").then(
        (res) => {
          if (res.code === 200) {
            this.$message.success("发布成功");
            this.TsFormData = {};
            this.transportShow = false;
          }
        }
      );
    },
    async saveBusiness(id) {
      this.ruleShow = true;
      this.ruleTitle = "担保协议";
      this.ruleContent = (
        await this.http(`/web/introduce/getbyid/19`)
      ).data.contentStr;
      this.ruleType = 3;
      this.id = id;
    },
    async tsSavebusiness(id) {
      this.ruleShow = true;
      this.ruleTitle = "担保协议";
      this.ruleContent = (
          await this.http(`/web/introduce/getbyid/19`)
      ).data.contentStr;
      this.ruleType = 3;
      this.id = id;
      this.tsType=true
    },
    //车长选择
    ccSelect(index) {
      this.ccData[index].checked = !this.ccData[index].checked;
      let text = "";
      this.ccData.map((item) => {
        if (item.checked) {
          text += item.label + "/";
        }
      });
      text = text.slice(0, text.length - 1);
      this.formData.carLength = text;
    },
    //车型选择
    cxSelect(index) {
      this.cxData[index].checked = !this.cxData[index].checked;
      let text = "";
      this.cxData.map((item) => {
        if (item.checked) {
          text += item.text + "/";
        }
      });
      text = text.slice(0, text.length - 1);
      this.formData.carType = text;
    },
    //方式选择
    fsSelect(index) {
      this.fsCurrent = index;
      this.formData.transportWay = this.fsData[index].text;
    },
    //装货地事件
    handleChange() {
      let loc = "";
      this.selectedOptions.map((item) => {
        loc += CodeToText[item] + "/";
      });
      let arr = loc.split("/");
      this.formData.placeAProvince = arr[0];
      this.formData.placeACity = arr[1];
      this.formData.placeACounty = arr[2];
    },
    screenHandleChange() {
      let loc = "";
      this.screenOptions.map((item) => {
        loc += CodeToText[item] + "/";
      });
      let arr = loc.split("/");
      this.buyScreen.placeAProvince = arr[0];
      this.buyScreen.placeACity = arr[1];
      this.buyScreen.placeACounty = arr[2];
      this.buyNetwork();
    },
    screenHandleChangeB() {
      let loc = "";
      this.screenOptionsB.map((item) => {
        loc += CodeToText[item] + "/";
      });
      let arr = loc.split("/");
      this.buyScreen.placeBProvince = arr[0];
      this.buyScreen.placeBCity = arr[1];
      this.buyScreen.placeBCounty = arr[2];
      this.buyNetwork();
    },
    //卸货地事件
    ChangeB() {
      let loc = "";
      this.selectedB.map((item) => {
        loc += CodeToText[item] + "/";
      });
      let arr = loc.split("/");
      this.formData.placeBProvince = arr[0];
      this.formData.placeBCity = arr[1];
      this.formData.placeBCounty = arr[2];
    },
    getCityCode(text) {
      let codearray = [];
      if (text !== "") {
        let cityarray = text.split("/");
        if (cityarray.length == 1) {
          codearray.push(TextToCode[cityarray[0]].code);
        } else if (cityarray.length == 2) {
          codearray.push(TextToCode[cityarray[0]].code);
          codearray.push(TextToCode[cityarray[0]][cityarray[1]].code);
        } else if (cityarray.length == 3) {
          codearray.push(TextToCode[cityarray[0]].code);
          codearray.push(TextToCode[cityarray[0]][cityarray[1]].code);
          codearray.push(
            TextToCode[cityarray[0]][cityarray[1]][cityarray[2]].code
          );
        }
      }
      return codearray;
    },
    fresh() {
      this.screenOptions = "";
      this.screenOptionsB = "";
      this.buyScreen = {
        pageNum: 1,
        pageSize: 10,
        carLength: "",
        transportWay: "",
        keyword: "",
      };
      this.buyNetwork();
    },
    onClose() {
      this.ruleTitle = "";
    },
    async elConfirm() {
      this.ruleShow = false;
      if (this.ruleType === 2) {
        this.formData = {};
        let userInfo = (await this.http("/web/user/getcurrentuserinfo")).data;
        this.formData.username = userInfo.username;
        this.formData.companyName = userInfo.companyName;
        this.formData.phoneNumber = userInfo.phoneNumber;
        this.selectedOptions = "";
        //卸货地
        this.selectedB = "";
        //重量（吨）
        this.weightTon = "";
        //重量（方）
        this.weightPower = "";
        // 价格（吨）
        this.priceTon = "";
        //价格（方）
        this.pricePower = "";
        this.ccData.map((item) => {
          item.checked = false;
        });
        this.cxData = [
          { text: "平板", checked: false },
          { text: "高栏", checked: false },
        ];
        this.fsData = [
          { text: "拼车", checked: false },
          { text: "整车", checked: false },
        ];
        this.sourceShow = true;
      }else if(this.tsType){
        this.http(
            `/web/transportability/applytransaction/${this.id}`,
            "",
            "post"
        ).then((res) => {
          if (res.code === 200) {
            this.$message.success("发起交易成功");
            this.TsFormData = {};
            this.transportShow = false;
            this.tsNetwork();
          }
        });
      } else {
        this.http(`/web/productsource/applytransaction/${this.id}`, "", "post").then(
          (res) => {
            if (res.code === 200) {
              this.$message.success("发起交易成功");
              this.sourceShow = false;
              this.formData = {};
              this.buyNetwork();
            }
          }
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  > .content {
    width: 1200px;
    margin: 50px auto;
    position: relative;
    > .row-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > .title {
        font-size: 24px;
        font-weight: 400;
        color: #1b294e;
        padding-left: 10px;
        border-left: solid 10px #1b294e;
      }
      > .screen {
        color: #00a0e9;
      }
      > .screen-left {
        display: flex;
        font-size: 14px;
        align-items: center;
        width: 50%;
        justify-content: space-between;
        margin: 30px 0;
      }
    }
    > .row-title {
      text-align: center;
      margin-top: 50px;
      font-size: 24px;
    }
    > .time {
      text-align: right;
      font-size: 18px;
      line-height: 30px;
    }
    > .welcome {
      font-size: 18px;
    }
    > .row-content {
      font-size: 18px;
      line-height: 40px;
      margin-top: 10px;
    }
  }
  > .col-right {
    display: flex;
    flex-direction: column;
    > img {
      width: 150px;
      height: 296px;
    }
    > .right-text {
      width: 150px;
      height: 148px;
      background: #ffffff;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      text-align: center;
      margin-top: 100px;
    }
  }
}
.popup-content {
  width: 1200px;
  padding-bottom: 50px;
  user-select: none;
  > .popup-header {
    width: calc(100% - 20px);
    height: 59px;
    background: #1b294e;
    line-height: 59px;
    font-size: 24px;
    font-weight: 400;
    color: #ffffff;
    padding-left: 20px;
  }
  .nomalBox {
    padding: 0 25px;
    height: 72px;
    line-height: 72px;
    background: #f6f6f6;
    border: 1px solid #e7edf1;
    border-radius: 2px;
    font-size: 24px;
    font-weight: 400;
    color: #333333;
    margin-right: 33px;
    cursor: pointer;
  }
  .activeBox {
    padding: 0 25px;
    height: 72px;
    line-height: 72px;
    background: #c9f0df;
    border: 1px solid #00b76a;
    border-radius: 2px;
    font-size: 24px;
    font-weight: 400;
    color: #00b76a;
    margin-right: 33px;
    cursor: pointer;
    position: relative;
    > img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 25px;
      height: 20px;
    }
  }
  > .popup-btns {
    display: flex;
    justify-content: space-between;

    > div {
      > .popup-cancel {
        width: 200px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background: #f6f6f6;
        border-radius: 5px;
        font-size: 24px;
        font-weight: 400;
        color: #999999;
        cursor: pointer;
      }

      > .popup-confirm {
        width: 200px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background: #00b76a;
        border-radius: 5px;
        font-size: 24px;
        font-weight: 400;
        color: #ffffff;
        margin: 0 50px;
        cursor: pointer;
      }
    }
  }
}
.TS_popup {
  width: 1000px;
  > .row_head {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    font-size: 18px;
    color: #333333;
    border: 1px solid #e7edf1;
  }
}
</style>
