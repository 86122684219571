<template>
  <div class="container">
    <Header :index="5"></Header>
    <div class="content">
      <div class="col-left">
        <div class="row-header">
          <p>项目合作</p>
          <p @click="addProject">项目发布</p>
        </div>
        <div
          class="row-content"
          v-for="(item, index) in cooperateData"
          :key="index"
        >
          <img :src="item.coverPhotoUrl" alt="" />
          <div class="col-right">
            <div class="title">{{ item.projectName }}</div>
            <p>{{ item.brief }}</p>
            <div class="row-bottom">
              <div class="col-time">
                <p>{{ item.createTime }}</p>
                <p @click="detail(item.id)">查看详情</p>
              </div>
              <div class="col-text">
                <p>{{ item.username }}&emsp;{{ item.phoneNumber }}</p>
                <p>{{ item.companyName }}</p>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%; text-align: center; margin: 70px 0">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="pageSize"
            layout="prev, pager, next, jumper, total, sizes"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div class="col-right">
        <img :src="advert.imgUrl" alt="" @click="openWindow(advert.linkUrl)" />
      </div>
    </div>
    <Footer></Footer>
    <van-popup
      v-model="show"
      get-container="body"
      :lazy-render="false"
      :close-on-click-overlay="false"
      z-index="99"
    >
      <div class="popup-content">
        <div class="popup-header">添加项目</div>
        <el-form label-width="100px" style="margin-left: 50px">
          <el-form-item label="封面图：" style="margin: 10px 0">
            <el-upload
              class="avatar-uploader"
              :action="baseUrl + '/web/upload/uploadimg'"
              :show-file-list="false"
              :headers="uploadHead"
              :on-success="handleAvatarSuccess"
            >
              <img v-if="imgUrl" :src="imgUrl" class="avatar" alt="" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="姓名：" style="margin-bottom: 10px">
            <el-input
              style="width: 400px"
              size="small"
              v-model="formData.username"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="公司：" style="margin-bottom: 10px">
            <el-input
              style="width: 400px"
              size="small"
              v-model="formData.companyName"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话：" style="margin-bottom: 10px">
            <el-input
              style="width: 400px"
              size="small"
              v-model="formData.phoneNumber"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="项目名称：" style="margin-bottom: 10px">
            <el-input
              style="width: 700px"
              size="small"
              v-model="formData.projectName"
            ></el-input>
          </el-form-item>
          <el-form-item label="项目简介：" style="margin-bottom: 10px">
            <el-input
              style="width: 700px"
              size="small"
              v-model="formData.brief"
            ></el-input>
          </el-form-item>
          <el-form-item label="项目详情：" style="margin-bottom: 10px">
            <div class="inputBox">
              <textarea
                type="text"
                placeholder="请输入"
                rows="6"
                v-model="formData.contentStr"
              />
              <div class="inputImg">
                <div class="img" v-for="(item, index) in photos" :key="index">
                  <img :src="item" alt="" />
                </div>
                <el-upload
                  :action="baseUrl + '/web/upload/uploadimg'"
                  :headers="uploadHead"
                  :show-file-list="false"
                  :on-success="handleSuccess"
                >
                  <div class="upload">
                    <van-icon name="photograph" size="50px" />
                    <p>上传图片</p>
                  </div>
                </el-upload>
                <div style="width: 150px"></div>
                <div style="width: 150px"></div>
              </div>
            </div>
          </el-form-item>
        </el-form>
        <div class="popup-btns">
          <div class="popup-cancel" @click="popup_cancel">取消</div>
          <div class="popup-confirm" @click="popup_confirm">发布</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<!--index.vue创建时间：2021/11/8 13:58:44-->
<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import { baseUrl } from "@/api/config";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      baseUrl,
      //图片上传Headers
      uploadHead: {
        Authorization: sessionStorage.getItem("token"),
      },
      show: false,
      page: 1,
      pageSize: 10,
      total: null,
      buyData: [],
      imgUrl: "",
      formData: {},
      cooperateData: [],
      advert: {},
      photos:[],
    };
  },
  mounted() {
    this.network();
    this.http("/web/advertisement/list", { type: 2 }).then((res) => {
      this.advert = res.data[0];
    });
  },
  methods: {
    network() {
      this.http("/web/projectcooperation/pageinhall", {
        pageNum: this.page,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res.code === 200) {
          this.cooperateData = res.data.rows;
          this.total = res.data.total;
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.network();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.network();
    },
    handleAvatarSuccess(res) {
      this.formData.coverPhotoUrl = res.data;
      this.imgUrl = res.data;
    },
    handleSuccess(res) {
      this.photos.push(res.data);
      this.formData.photosUrl = this.photos.join(",");
    },
    async addProject() {
      let flag = await this.http("/web/user/checkuserinfocomplete").then(
        (res) => {
          if (!res.data) {
            this.$store.commit("open", true);
            return false;
          }
          if (res.data) {
            return true;
          }
        }
      );
      if (!flag) {
        return;
      }
      this.formData = {};
      this.imgUrl = "";
      let userInfo = (await this.http("/web/user/getcurrentuserinfo")).data;
      this.formData.username = userInfo.username;
      this.formData.companyName = userInfo.companyName;
      this.formData.phoneNumber = userInfo.phoneNumber;
      this.show = true;
    },
    popup_cancel() {
      this.show = false;
    },
    popup_confirm() {
      if (!this.formData.coverPhotoUrl) {
        this.$message("请上传封面图");
        return;
      }
      if (!this.formData.projectName) {
        this.$message("请输入项目名称");
        return;
      }
      if (!this.formData.brief) {
        this.$message("请输入项目简介");
        return;
      }
      if (!this.formData.contentStr) {
        this.$message("请输入项目详情");
        return;
      }
      if(!this.formData.photosUrl){
        this.$message("请上传图片");
        return;
      }
      this.http("/web/projectcooperation/add", this.formData, "post").then(
        (res) => {
          if (res.code === 200) {
            this.$message.success("发布成功");
            this.show = false;
            this.network();
          }
        }
      );
    },
    detail(id) {
      this.$router.push({
        path: "/detail",
        query: { id, index: 5 },
      });
    },
    openWindow(url) {
      if (url) {
        window.open(url, "blank");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  > .content {
    width: 1200px;
    margin: 50px auto;
    display: flex;
    justify-content: space-between;
    > .col-left {
      width: 1000px;
      > .row-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 53px;
        > p:first-child {
          font-size: 24px;
          font-weight: 400;
          color: #1b294e;
          border-left: solid 10px #1b294e;
          padding-left: 10px;
        }
        > p:last-child {
          width: 82px;
          height: 31px;
          line-height: 31px;
          text-align: center;
          background: #ff945b;
          border-radius: 2px;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }
      }
      > .row-content {
        padding: 20px;
        width: 960px;
        height: 180px;
        background: #ffffff;
        box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: space-between;
        margin-bottom: 23px;
        > img {
          width: 224px;
          height: 180px;
        }
        > .col-right {
          width: 700px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          > .title {
            width: fit-content;
            font-size: 20px;
            font-weight: bold;
            color: #333333;
            cursor: pointer;
            &:hover {
              color: #ff945b;
            }
          }
          > p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 30px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            word-break: break-all;
            overflow: hidden;
          }
          > .row-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            > .col-time {
              > p:first-child {
                font-size: 16px;
                font-weight: 400;
                color: #999999;
              }
              > p:last-child {
                width: 100px;
                height: 34px;
                line-height: 34px;
                text-align: center;
                border: 1px solid #ff945b;
                border-radius: 2px;
                font-size: 16px;
                font-weight: 400;
                color: #ff945b;
                margin-top: 20px;
                cursor: pointer;
              }
            }
            > .col-text {
              text-align: center;
              background: rgba(27, 41, 78, 0.1);
              > p:first-child {
                padding: 9px 14px;
                background: #1b294e;
                font-size: 16px;
                font-weight: 400;
                color: #ffffff;
              }
              > p:last-child {
                padding: 9px 0;
                font-size: 16px;
                font-weight: 400;
                color: #1b294e;
              }
            }
          }
        }
      }
    }
    > .col-right {
      width: 150px;
      height: auto;
      display: flex;
      flex-direction: column;
      > img {
        width: 150px;
        height: 296px;
        position: fixed;
        top: 200px;
        cursor: pointer;
      }
      > .right-text {
        width: 150px;
        height: 198px;
        background: #ffffff;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        text-align: center;
        position: fixed;
        top: 600px;
      }
    }
  }
}
.popup-content {
  width: 950px;
  padding-bottom: 20px;
  z-index: 1 !important;
  user-select: none;
  > .popup-header {
    height: 50px;
    line-height: 50px;
    padding-left: 55px;
    font-size: 22px;
    font-weight: 400;
    color: #333333;
    border-bottom: solid 1px #e5e5e5;
  }
  > .popup-btns {
    display: flex;
    justify-content: right;
    > .popup-cancel {
      width: 200px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background: #f6f6f6;
      border-radius: 5px;
      font-size: 24px;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
    > .popup-confirm {
      width: 200px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background: #00b76a;
      border-radius: 5px;
      font-size: 24px;
      font-weight: 400;
      color: #ffffff;
      margin: 0 50px;
      cursor: pointer;
    }
  }
}
.inputBox {
  width: calc(100% - 155px);
  border: 1px solid #eeeeee;
  border-radius: 2px;
  padding: 0 27px 20px;
  > textarea {
    width: 100%;
    border: none;
    resize: none;
  }
  .inputImg {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    > .img {
      width: 150px;
      height: 150px;
      overflow: hidden;
      margin-bottom: 15px;
      border-radius: 5px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .upload {
      width: 150px;
      height: 150px;
      display: flex;
      flex-direction: column;
      color: #999999;
      align-items: center;
      justify-content: center;
      background-color: #eeeeee;
      cursor: pointer;
    }
  }
}
.avatar-uploader-icon:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: dashed 1px #999999;
}
.avatar {
  width: 100px;
  height: 100px;
}
</style>
