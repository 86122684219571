<template>
  <div class="container">
    <Header :index="index" ref="top"></Header>
    <div class="content">
      <p>{{ index === 5 ? detailData.projectName : detailData.title }}</p>
      <p>
        发表时间：{{
          index === 5 ? detailData.createTime : detailData.publishTime
        }}
      </p>
      <div class="line"></div>
      <div class="title" v-if="index === 5">
        <img :src="detailData.coverPhotoUrl" alt="" />
        <div class="col-right">
          <p>联系方式</p>
          <p>{{ detailData.username }}</p>
          <p>{{ detailData.phoneNumber }}</p>
          <p>{{ detailData.companyName }}</p>
        </div>
      </div>
      <div v-html="detailData.contentStr"></div>
      <div class="imgs" v-if="index === 5&&detailData.photosUrl">
        <img
          :src="item"
          alt=""
          v-for="(item, index) in detailData.photosUrl.split(',')"
          :key="index"
        />
      </div>
      <div class="line" style="margin-top: 57px"></div>
      <div class="flex">
        <p @click="show(detailData.nextVO.id)">
          下一篇：{{
            detailData.nextVO
              ? index === 5
                ? detailData.nextVO.projectName
                  ? detailData.nextVO.projectName
                  : "已经是最后一篇了！"
                : detailData.nextVO.title
                ? detailData.nextVO.title
                : "已经是最后一篇了！"
              : ""
          }}
        </p>
        <p v-if="$route.query.type">
          分类：{{
            $route.query.type === "1"
              ? "公告"
              : $route.query.type === "2"
              ? "规则"
              : $route.query.type === "3"
              ? "新手指南"
              : $route.query.type === "4"
              ? "行业要闻"
              : ""
          }}
        </p>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<!--index.vue创建时间：2021/11/24 14:05:29-->
<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      index: 0,
      detailData: {},
    };
  },
  beforeMount() {
    if (this.$route.query.type === undefined) {
      this.index = Number(this.$route.query.index);
    }
  },
  mounted() {
    if (this.$route.query.index === "5") {
      this.http(`/web/projectcooperation/getbyid/${this.$route.query.id}`).then(
        (res) => {
          if (res.code === 200) {
            this.detailData = res.data;
          }
        }
      );
    } else if (this.$route.query.index === "9") {
      this.http(`/web/partner/getbyid/${this.$route.query.id}`).then((res) => {
        if (res.code === 200) {
          this.detailData = res.data;
        }
      });
    } else {
      if (this.$route.query.type === "4") {
        this.http(`/web/news/getbyid/${this.$route.query.id}`).then((res) => {
          if (res.code === 200) {
            this.detailData = res.data;
          }
        });
      } else {
        this.http(`/web/notice/getbyid/${this.$route.query.id}`).then((res) => {
          if (res.code === 200) {
            this.detailData = res.data;
          }
        });
      }
    }
  },
  methods: {
    show(id) {
      if (id) {
        this.http(`/web/notice/getbyid/${id}`).then((res) => {
          if (res.code === 200) {
            this.detailData = res.data;
          }
        });
        this.$refs["top"].$el.scrollIntoView({
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  > .content {
    width: 1200px;
    margin: 50px auto 65px;
    > p:nth-of-type(1) {
      font-size: 26px;
      font-weight: 400;
      color: #333333;
    }
    > p {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      margin: 35px 0 45px;
    }
    > .line {
      width: 100%;
      height: 1px;
      background: #dddddd;
      margin-bottom: 48px;
    }
    > .title {
      width: 1200px;
      height: 350px;
      background: #eeeeee;
      box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.1);
      display: flex;
      margin-bottom: 50px;
      > img {
        width: 600px;
        height: 100%;
      }
      > .col-right {
        margin-left: 70px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        >p:nth-of-type(1){
          font-size: 18px;
          font-weight: bold;
          color: #1B294E;
        }
        >p{
          font-size: 18px;
          font-weight: 400;
          color: #1B294E;
        }
      }
    }
    > .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      user-select: none;
      > p:nth-of-type(1) {
        cursor: pointer;
      }
    }
    > .imgs {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      > img {
        max-width: 100%;
        margin-right: 10px;
      }
    }
  }
}
</style>
