<template>
  <div class="container">
    <Header :index="8"></Header>
    <div class="content">
      <div class="col-left">
        <div class="row-head">
          <p>合作小店</p>
          <p @click="showEvent">我要合作</p>
        </div>
        <div class="screen">
          <div class="screen-left">
<!--            分类-->
<!--            <el-select-->
<!--              v-model="screen.type"-->
<!--              style="width: 100px"-->
<!--              size="small"-->
<!--              @change="network"-->
<!--            >-->
<!--              <el-option label="送餐服务" value="送餐服务"></el-option>-->
<!--              <el-option label="桶装水" value="桶装水"></el-option>-->
<!--              <el-option label="办公耗材" value="办公耗材"></el-option>-->
<!--              <el-option label="绿植租赁" value="绿植租赁"></el-option>-->
<!--              <el-option label="保洁服务" value="保洁服务"></el-option>-->
<!--            </el-select>-->
            地区
            <el-cascader
                placeholder="请选择省市区"
                :options="options"
                separator="-"
                v-model="screenAddress"
                @change="screenChange"
            ></el-cascader>
          </div>
          <div class="screen-right">
            <el-input
              placeholder="请输入需要查询的内容"
              size="small"
              style="width: 200px"
              v-model="screen.keyWord"
            ></el-input>
            <van-button type="primary" size="small" color="#1B294E" @click="network"
              >搜索</van-button
            >
          </div>
        </div>
        <div class="list" v-for="(item, index) in shopData" :key="index">
          <img :src="item.coverPhotoUrl" alt="" />
          <div class="col-right">
            <div class="col-title">
              <div>
                {{ item.shopName }}
<!--                <p>{{ item.type }}</p>-->
              </div>
              <p>
                {{
                  item.provinceName +
                  (item.cityName?item.cityName:'') +
                  (item.countyName?item.countyName:'') +
                  (item.address?item.address:'')
                }}
              </p>
            </div>
            <p>简介：{{item.brief}}</p>
            <p>营业时间：{{ item.workStartTime + "-" + item.workEndTime }}</p>
            <p>主营：{{ item.mainBusiness }}</p>
          </div>
        </div>
        <div style="width: 100%; text-align: right; padding-top: 50px">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="screen.pageNum"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="screen.pageSize"
            layout="prev, pager, next, jumper, total, sizes"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div class="col-right">
        <img :src="advert.imgUrl" alt="" @click="openWindow(advert.linkUrl)" />
      </div>
    </div>
    <Footer></Footer>
    <van-popup get-container="body" z-index="99" v-model="show">
      <div class="popup-content">
        <div class="row-head">小店申请</div>
        <el-form label-width="80px" style="padding: 20px 0 50px 50px">
          <el-form-item label="小店名称">
            <el-input
              placeholder="请输入小店名称"
              style="width: 300px"
              v-model="formData.shopName"
            ></el-input>
          </el-form-item>
<!--          <el-form-item label="小店分类">-->
<!--            <el-select-->
<!--              v-model="formData.type"-->
<!--              placeholder="请选择"-->
<!--              style="width: 300px"-->
<!--            >-->
<!--              <el-option label="送餐服务" value="送餐服务"></el-option>-->
<!--              <el-option label="桶装水" value="桶装水"></el-option>-->
<!--              <el-option label="办公耗材" value="办公耗材"></el-option>-->
<!--              <el-option label="绿植租赁" value="绿植租赁"></el-option>-->
<!--              <el-option label="保洁服务" value="保洁服务"></el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
          <el-form-item label="小店主营">
            <el-input
              placeholder="请输入小店主营"
              style="width: 300px"
              v-model="formData.mainBusiness"
            ></el-input>
          </el-form-item>
          <el-form-item label="小店简介">
          <el-input
              placeholder="请输入小店简介"
              style="width: 300px"
              v-model="formData.brief"
          ></el-input>
        </el-form-item>
          <el-form-item label="营业时间">
            <el-time-picker
              is-range
              style="width: 300px"
              v-model="time"
              range-separator="至"
              start-placeholder="开始营业时间"
              end-placeholder="结束营业时间"
              placeholder="选择时间范围"
              value-format="HH:mm"
              format="HH:mm"
            >
            </el-time-picker>
          </el-form-item>
          <el-form-item label="小店地址"
            ><el-cascader
              placeholder="请选择省市区"
              :options="option"
              separator="-"
              v-model="address"
              @change="handleChange"
            ></el-cascader>
            <el-input
              style="width: 180px; margin-left: 10px"
              placeholder="请输入详细地址"
              v-model="formData.address"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="小店图片">
            <el-upload
              class="avatar-uploader"
              :action="baseUrl + '/web/upload/uploadimg'"
              :headers="uploadHead"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" alt="" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-form>
        <div class="btns">
          <div></div>
          <div>
            <div class="cancel" @click="cancel">取消</div>
            <div class="confirm" @click="confirm">确认</div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<!--index.vue创建时间：2021/11/9 14:26:43-->
<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import { CodeToText, regionDataPlus,regionData } from "element-china-area-data";
import { baseUrl } from "@/api/config";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      baseUrl,
      //图片上传Headers
      uploadHead: {
        Authorization: sessionStorage.getItem("token"),
      },
      //地区
      options: regionDataPlus,
      option:regionData,
      imageUrl: "",
      time: "",
      address: [],
      total: null,
      screen: {
        pageNum: 1,
        pageSize: 10,
        type: "",
        keyWord:"",
        provinceName:"",
        cityName:"",
        countyName:""
      },
      formData: {},
      shopData: [],
      show: false,
      screenAddress:[],
      advert:{}
    };
  },
  mounted() {
    this.network();
    this.http('/web/advertisement/list',{type:2}).then(res=>{
      this.advert=res.data[0]
    })
  },
  methods: {
    network() {
      this.http("/web/shop/pageinhall", this.screen).then((res) => {
        if (res.code === 200) {
          this.total = res.data.total;
          this.shopData = res.data.rows;
        }
      });
    },
    handleSizeChange(val) {
      this.screen.pageSize = val;
      this.network();
    },
    handleCurrentChange(val) {
      this.screen.pageNum = val;
      this.network();
    },
    handleAvatarSuccess(res) {
      this.imageUrl = res.data;
    },
    async showEvent(){
      let flag = await this.http("/web/user/checkuserinfocomplete").then(
          (res) => {
            if (!res.data) {
              this.$store.commit("open", true);
              return false;
            }
            if (res.data) {
              return true;
            }
          }
      );
      if (!flag) {
        return;
      }
      this.show=true
    },
    cancel() {
      this.show = false;
    },
    confirm() {
      if (!this.formData.shopName) {
        this.$message("请输入小店名称");
        return;
      }
      // if (!this.formData.type) {
      //   this.$message("请选择小店类型");
      //   return;
      // }
      if (!this.formData.mainBusiness) {
        this.$message("请输入小店主营");
        return;
      }
      if (!this.time) {
        this.$message("请选择营业时间");
        return;
      }
      if (!this.formData.provinceName) {
        this.$message("请选择小店地址");
        return;
      }
      if (!this.imageUrl) {
        this.$message("请上传小店图片");
        return;
      }
      this.formData.workStartTime = this.time[0];
      this.formData.workEndTime = this.time[1];
      this.formData.coverPhotoUrl = this.imageUrl;
      this.http("/web/shop/add", this.formData, "post").then((res) => {
        if (res.code === 200) {
          this.$message.success("发布成功");
          this.formData = {};
          this.address = [];
          this.time = "";
          this.imageUrl = "";
          this.show = false;
          this.network()
        }
      });
    },
    handleChange() {
      let loc = "";
      this.address.map((item) => {
        loc += CodeToText[item] + "/";
      });
      let arr = loc.split("/");
      this.formData.provinceName = arr[0];
      this.formData.cityName = arr[1];
      this.formData.countyName = arr[2];
    },
    screenChange(){
      let loc = "";
      this.screenAddress.map((item) => {
        loc += CodeToText[item] + "/";
      });
      let arr = loc.split("/");
      this.screen.provinceName = arr[0];
      this.screen.cityName = arr[1];
      this.screen.countyName = arr[2];
      this.network()
    },
    openWindow(url){
      if(url){
        window.open(url,"blank")
      }
    }
  },
};
</script>

<style scoped lang="scss">
.container {
  > .content {
    width: 1200px;
    margin: 50px auto;
    display: flex;
    justify-content: space-between;
    user-select: none;
    > .col-left {
      width: 1000px;
      > .row-head {
        display: flex;
        justify-content: space-between;
        > p:first-child {
          padding-left: 10px;
          border-left: 10px solid #1b294e;
          font-size: 24px;
        }
        p:last-child {
          background-color: #ff945b;
          color: #ffffff;
          width: 80px;
          font-size: 16px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          border-radius: 5px;
          cursor: pointer;
        }
      }
      > .screen {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        > .screen-left {
          width: 260px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        > .screen-right {
          display: flex;
          align-items: center;
        }
      }
      > .list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
        > img {
          width: 120px;
          height: 120px;
          border-radius: 5px;
        }
        > .col-right {
          width: calc(100% - 140px);
          height: 120px;
          padding-left: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          > .col-title {
            display: flex;
            justify-content: space-between;
            > div {
              font-size: 16px;
              color: #333;
              display: flex;
              align-items: center;
              > p {
                font-size: 14px;
                margin-left: 10px;
                background-color: #e2faff;
                color: #1277d7;
                padding: 3px 10px;
              }
            }
            > p {
              color: #666666;
              font-size: 16px;
            }
          }
          > p:nth-of-type(1),p:nth-of-type(2) {
            font-size: 12px;
            color: #666666;
          }
          > p:last-child {
            font-size: 14px;
          }
        }
      }
    }
    > .col-right {
      width: 150px;
      height: auto;
      display: flex;
      flex-direction: column;

      > img {
        width: 150px;
        height: 296px;
        position: fixed;
        top: 200px;
        cursor: pointer;
      }
    }
  }
}
.popup-content {
  width: 846px;
  padding-bottom: 20px;
  > .row-head {
    padding-left: 20px;
    height: 50px;
    line-height: 50px;
    background-color: rgb(27, 41, 78);
    color: rgb(255, 255, 255);
    font-size: 24px;
  }
  .avatar-uploader-icon:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border: dashed 1px;
  }
  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
  > .btns {
    display: flex;
    justify-content: space-between;
    > div {
      display: flex;
      > .cancel {
        width: 200px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background: #f6f6f6;
        border-radius: 5px;
        font-size: 24px;
        font-weight: 400;
        color: #999999;
        cursor: pointer;
      }

      > .confirm {
        width: 200px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background: #00b76a;
        border-radius: 5px;
        font-size: 24px;
        font-weight: 400;
        color: #ffffff;
        margin: 0 50px;
        cursor: pointer;
      }
    }
  }
}
</style>
