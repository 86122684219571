<template>
  <div class="container">
    <Header :index="0"></Header>
    <div class="content">
      <p>{{detailData.remark}}</p>
      <p>发布时间：{{detailData.createTime}}</p>
      <div class="line"></div>
      <div v-html="detailData.contentStr"></div>
    </div>
    <Footer></Footer>
  </div>
</template>
<!-- 2021/12/2 17:24:24-->
<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      detailData:{}
    }
  },
  async mounted() {
    this.detailData=(await this.http(`/web/introduce/getbyid/${this.$route.query.id}`)).data
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.container {
  width:100%;
  >.content{
    width:1200px;
    margin:50px auto 65px;
    >p:nth-of-type(1){
      font-size: 26px;
      font-weight: 400;
      color: #333333;
    }
    >p{
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      margin:35px 0 45px;
    }
    >.line{
      width: 100%;
      height: 1px;
      background: #DDDDDD;
      margin-bottom: 48px;
    }
    >.flex{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      user-select: none;
      >p:nth-of-type(1){
        cursor: pointer
      }
    }
  }
}
</style>