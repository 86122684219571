<template>
  <div class="container">
    <Header :index="9"></Header>
    <img src="@/assets/partner/head.png" alt="" />
    <div class="content">
      <div class="col-left">
        <div class="change">
          <div class="text" v-for="(item, index) in changeData" :key="index">
            <div
              :class="changeCurrent === index ? 'activeText' : ''"
              @click="change(item.id, index)"
            >
              {{ item.text }}
              <div class="line" v-if="changeCurrent === index"></div>
            </div>
            <span v-if="index !== changeData.length - 1">|</span>
          </div>
        </div>
        <p>合作伙伴</p>
        <div class="list" v-for="(item, index) in partnerData" :key="index">
          <img :src="item.coverPhotoUrl" alt="" />
          <div class="col-right">
            <p>{{ item.title }}</p>
            <p>{{ item.brief }}</p>
            <p>{{ item.publishTime }}</p>
            <div class="row-bottom">
              <p @click="$router.push({
              path:'/detail',
              query:{id:item.id,index:9}
              })">查看详情</p>
              <div>
                <img
                  :src="
                    id === 1
                      ? require('@/assets/partner/gangmao.png')
                      : id === 2
                      ? require('@/assets/partner/yunshu.png')
                      : id === 3
                      ? require('@/assets/partner/jianzhu.png')
                      : id === 4
                      ? require('@/assets/partner/jiagong.png')
                      : id === 5
                      ? require('@/assets/partner/cupin.png')
                      : ''
                  "
                  alt=""
                />
                {{
                  id === 1
                    ? "钢贸行业"
                    : id === 2
                    ? "运输行业"
                    : id === 3
                    ? "建筑行业"
                    : id === 4
                    ? "加工行业"
                    : id === 5
                    ? "促品展示"
                    : ""
                }}
              </div>
            </div>
          </div>
        </div>
        <div style="text-align: center;margin:50px 0;">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="pageSize"
            layout="prev, pager, next, jumper, total, sizes"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div class="col-right">
        <img :src="advert.imgUrl" alt="" @click="openWindow(advert.linkUrl)" />
        <div class="right-text">
          <div @click="change(1, 0)"><img src="@/assets/partner/gangmao.png" alt="" />钢贸行业</div>
          <div @click="change(2, 1)"><img src="@/assets/partner/yunshu.png" alt="" />运输行业</div>
          <div @click="change(3, 2)"><img src="@/assets/partner/jianzhu.png" alt="" />建筑行业</div>
          <div @click="change(4, 3)"><img src="@/assets/partner/jiagong.png" alt="" />加工行业</div>
          <div @click="change(5, 4)"><img src="@/assets/partner/cupin.png" alt="" />促品展示</div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<!--index.vue创建时间：2021/11/9 15:16:28-->
<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      page: 1,
      pageSize: 10,
      total: null,
      id: 1,
      changeCurrent: 0,
      changeData: [
        {
          id: 1,
          text: "钢贸行业",
        },
        {
          id: 2,
          text: "运输行业",
        },
        {
          id: 3,
          text: "建筑行业",
        },
        {
          id: 4,
          text: "加工行业",
        },
        {
          id: 5,
          text: "促品展示",
        },
      ],
      partnerData: [],
      advert:{},
    };
  },
  mounted() {
    this.network();
    this.http('/web/advertisement/list',{type:2}).then(res=>{
      this.advert=res.data[0]
    })
  },
  methods: {
    network() {
      this.http(`/web/partner/page/${this.id}`, {
        pageNum: this.page,
        pageSize: this.pageSize,
      }).then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.partnerData = res.data.rows;
          this.total = res.data.total;
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.network()
    },
    handleCurrentChange(val) {
      this.page = val;
      this.network()
    },
    change(id, index) {
      this.id = id;
      this.changeCurrent = index;
      this.network()
    },
    openWindow(url){
      if(url){
        window.open(url,"blank")
      }
    }
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  > img {
    width: 100%;
    height: 400px;
  }
  > .content {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    > .col-left {
      width: 1000px;
      > .change {
        width: fit-content;
        margin: 80px auto;
        display: flex;
        > .text {
          display: flex;
          font-size: 18px;
          font-weight: 400;
          color: #999999;

          > span {
            color: #dddddd;
            margin: 0 36px;
          }

          > .activeText {
            font-size: 18px;
            font-weight: 400;
            color: #1b294e;
          }

          > div {
            cursor: pointer;
            > .line {
              width: 100%;
              height: 2px;
              background: #1b294e;
              border-radius: 1px;
              margin-top: 14px;
            }
          }
        }
      }
      > p {
        font-size: 24px;
        font-weight: 400;
        color: #1b294e;
        padding-left: 10px;
        border-left: solid 10px #1b294e;
        margin-bottom: 65px;
      }
      > .list {
        width: 960px;
        height: 180px;
        padding: 20px;
        background: #ffffff;
        box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.1);
        margin-bottom: 36px;
        display: flex;
        justify-content: space-between;
        > img {
          width: 224px;
          height: 180px;
        }
        > .col-right {
          width: 700px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          > p:nth-of-type(1) {
            font-size: 20px;
            font-weight: bold;
            color: #ff945b;
          }
          > p:nth-of-type(2) {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 30px;
          }
          > p:nth-of-type(3) {
            font-size: 16px;
            font-weight: 400;
            color: #999999;
          }
          > .row-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            > p {
              width: 100px;
              height: 34px;
              line-height: 34px;
              text-align: center;
              border: 1px solid #ff945b;
              border-radius: 2px;
              font-size: 16px;
              font-weight: 400;
              color: #ff945b;
              cursor: pointer;
            }
            > div {
              display: flex;
              align-items: center;
              font-size: 14px;
              font-weight: 400;
              color: #999999;
              > img {
                width: 25px;
                height: 25px;
                margin-right: 20px;
              }
            }
          }
        }
      }
    }
    > .col-right {
      width: 150px;
      height: auto;
      display: flex;
      flex-direction: column;
      > img {
        width: 150px;
        height: 296px;
        position: fixed;
        top: 200px;
        cursor: pointer;
      }
      > .right-text {
        width: 150px;
        height: 198px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        text-align: center;
        position: fixed;
        top: 600px;
        > div {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          cursor: pointer;
          > img {
            width: 25px;
            height: 25px;
            margin-right: 20px;
          }
        }
      }
    }
  }
}
</style>
