<template>
  <div class="container">
    <Header :index="0"></Header>
    <div class="content">
      <div class="col-left">
        <div class="row-header">
          <p>行业要闻</p>
        </div>
        <div class="row-content" v-for="(item, index) in newsData" :key="index">
          <img :src="item.coverPhotoUrl" alt="" />
          <div class="col-right">
            <div class="title">{{ item.title }}</div>
            <p>{{ item.brief }}</p>
            <div class="row-bottom">
              <div class="col-time">
                <p>{{ item.publishTime }}</p>
                <p @click="toDetail(item.id, item.type)">查看详情</p>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%; text-align: center; margin: 70px 0">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="pageSize"
            layout="prev, pager, next, jumper, total, sizes"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div class="col-right">
        <img :src="advert.imgUrl" alt="" @click="openWindow(advert.linkUrl)" />
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<!-- 2021/12/2 16:21:21-->
<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      newsData: [],
      page: 1,
      pageSize: 10,
      total: null,
      advert:{}
    };
  },
  async mounted() {
    await this.network();
    this.http('/web/advertisement/list',{type:2}).then(res=>{
      this.advert=res.data[0]
    })
  },
  methods: {
    async network() {
      await this.http("/web/news/page", {
        pageNum: this.page,
        pageSize: this.pageSize,
      }).then((res) => {
        this.newsData = res.data.rows;
        this.total = res.data.total;
      });
    },
    toDetail(id, type) {
      this.$router.push({ path: "/detail", query: { id, type } });
    },
    async handleSizeChange(val) {
      this.pageSize = val;
      await this.network();
    },
    async handleCurrentChange(val) {
      this.page = val;
      await this.network();
    },
    openWindow(url){
      if(url){
        window.open(url,"blank")
      }
    }
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  > .content {
    width: 1200px;
    margin: 50px auto;
    display: flex;
    justify-content: space-between;
    > .col-left {
      width: 1000px;
      > .row-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 53px;
        > p:first-child {
          font-size: 24px;
          font-weight: 400;
          color: #1b294e;
          border-left: solid 10px #1b294e;
          padding-left: 10px;
        }
      }
      > .row-content {
        padding: 20px;
        width: 960px;
        height: 180px;
        background: #ffffff;
        box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: space-between;
        margin-bottom: 23px;
        > img {
          width: 224px;
          height: 180px;
        }
        > .col-right {
          width: 700px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          > .title {
            width: fit-content;
            font-size: 20px;
            font-weight: bold;
            color: #333333;
            cursor: pointer;
            &:hover {
              color: #ff945b;
            }
          }
          > p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 30px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            word-break: break-all;
            overflow: hidden;
          }
          > .row-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            > .col-time {
              > p:first-child {
                font-size: 16px;
                font-weight: 400;
                color: #999999;
              }
              > p:last-child {
                width: 100px;
                height: 34px;
                line-height: 34px;
                text-align: center;
                border: 1px solid #ff945b;
                border-radius: 2px;
                font-size: 16px;
                font-weight: 400;
                color: #ff945b;
                margin-top: 20px;
                cursor: pointer;
              }
            }
            > .col-text {
              text-align: center;
              background: rgba(27, 41, 78, 0.1);
              > p:first-child {
                padding: 9px 14px;
                background: #1b294e;
                font-size: 16px;
                font-weight: 400;
                color: #ffffff;
              }
              > p:last-child {
                padding: 9px 0;
                font-size: 16px;
                font-weight: 400;
                color: #1b294e;
              }
            }
          }
        }
      }
    }
    > .col-right {
      width: 150px;
      height: auto;
      display: flex;
      flex-direction: column;
      > img {
        width: 150px;
        height: 296px;
        position: fixed;
        top: 200px;
        cursor: pointer;
      }
      > .right-text {
        width: 150px;
        height: 198px;
        background: #ffffff;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        text-align: center;
        position: fixed;
        top: 600px;
      }
    }
  }
}
</style>
